import React, { useState } from "react";
import cascading from "../assests/css/PredictionModalStyle.module.css";
import { Modal } from "react-bootstrap";

const PredictionModal = (props) => {
  // const [showmodal, setShowmodal] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);


  const continueScript = async () => {
    if (checkbox1 && checkbox2) {
      props.onHide()
    }
  }

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
        backdrop="static"
        size="md"
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle} mb-0`}>Welcome!</h4>
          {/* <IoMdClose
                className={`${cascading.closebtn}`}
                onClick={props.onHide}
              /> */}
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div>
            <p className={`${cascading.bodyTitle}`}>This product is in beta</p>
            <p className={`${cascading.desc}`}>
              Once you enter a position, you cannot cancel or adjust it.
            </p>
            <div className={`row`}>
              <div className={`col-2`}>
                <div className={`${cascading.checkbox} position-relative`}>
                  <label class="mdl_container">

                    <input type="checkbox" name="Check1" checked={checkbox1} onChange={(e) => setCheckbox1(e.target.checked)} />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className={`col-10`}>
                <p className={`${cascading.confirmText}`}>
                  I understand that I am using this product at my own risk. Any
                  losses incurred due to my actions are my own responsibility.
                </p>
              </div>
            </div>{" "}
            <div className={`row`}>
              <div className={`col-2`}>
                {" "}
                <div className={`${cascading.checkbox} position-relative`}>
                  <label class="mdl_container">
                    <input type="checkbox" name="Check2" checked={checkbox2} onChange={(e) => setCheckbox2(e.target.checked)} />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className={`col-10`}>
                <p className={`${cascading.confirmText}`}>
                  I understand that this product is still in beta. I am
                  participating at my own risk
                </p>
              </div>
            </div>
            <button
              className={`${cascading.continueButton}`}

              disabled={(checkbox1 && checkbox2) ? false : true}
              // onClick={() => props.onHide()}
              onClick={() => continueScript()}
            >
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PredictionModal;
