import React, { useEffect, useState } from "react";
import cascading from "../assests/css/farmcardstyle.module.css";
import logo from "../assests/images/cardlogo.png";
import Walletmodal from "../modals/walletmodal";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp, FaExternalLinkAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { AiOutlineCalculator } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import Calculatormodal from "../modals/calculatormodal";
import Stakemodal from "../modals/stakemodal";
import Unstakemodal from "../modals/unStakemodal";
import toast, { Toaster } from "react-hot-toast";
import BigNumber from "bignumber.js";
import { REFERRER_ADDRESS } from "../config/env";
import {roundToSignificant} from '../hooks/useCommon'

//hooks
import { getTokens, GetCurrencyBalance, multicall } from "../hooks/useTokens";
import {
  ApproveFarms,
  CheckIsApproved,
  Harvest,
  Deposit,
} from "../hooks/useMasterChef";
import { UserDeposits } from "../hooks/useStake";
import { UseLpTokenPrice } from "../hooks/useFarms";
import { GetChainIndex } from "../hooks/useWeb3";

//lib
import fileObjectUrl from "../lib/img";
import { toFixedNumber } from "../lib/FixedNumber";
import { getWalletAddress } from "../lib/localStorage";
import { isEmpty } from "../lib/isEmpty";

import { CHAINS } from "../config/env";

export default function Farmcard(props) {
  const [showmodal, setShowmodal] = useState(false);
  const [detailshow, setDetailshow] = useState(false);
  const [calshow, setcalshow] = useState(false);
  const [token, setToken] = useState(false);
  const [quoteToken, setQuoteToken] = useState(false);
  const [stakedAmount, setStakedAmount] = useState(false);
  const [checkApprove, setCheckApprove] = useState(false);
  const [value, setValue] =
    useState(
      115792089237316195423570985008687907853269984665640564039457584007913129639935
    );
  const [pending, setPending] = useState(false);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [isenable, setIsenable] = useState(true);
  const [earningsBusd, setEarningsBusd] = useState();
  const [liquidity, setLiquidity] = useState();
  const [stakedUSDvalue, setStakedUSDvalue] = useState();
  const [nativePrice, setNativePrice] = useState();

  const [stakemodal, setStakemodal] = useState(false);
  const [unStakemodal, setUnStakemodal] = useState(false);

  const userdetail = useSelector((state) => state.admin);

  useEffect(() => {
    console.log(
      "props_datas",
      props?.datas,
      props?.datas?.tokenPriceBusd,
      props?.datas?.quoteTokenPriceBusd
    );
    let token1 = getTokens()?.find(
      (val) =>
        val?.address?.toLowerCase() == props?.datas?.tokenAddress.toLowerCase()
    );
    console.log("token1", token1);
    setToken(token1);
    let token2 = getTokens()?.find(
      (val) =>
        val?.address?.toLowerCase() ==
        props?.datas?.quoteTokenAddress?.toLowerCase()
    );
    console.log("token2", token2);
    setQuoteToken(token2);
  }, [props]);

  useEffect(() => {
    stakedUSD();
  },[]);

  const stakedUSD = () => {
    let value = UseLpTokenPrice(props?.datas?.lpSymbol);
    console.log("stakedUSD", new BigNumber(value).toString());
    // setStakedUSDvalue(new BigNumber(value).toString());
    setStakedUSDvalue(new BigNumber(value).times(stakedAmount).toString())
  };

  useEffect(() => {
    localFn();
  },[]);

  const localFn = () => {
    var price = localStorage.getItem("Farms");
    price = JSON.parse(price);
    console.log("price_price", price);
    let nativePrice = price?.find((val) => val?.pid == 1).tokenPriceBusd;
    console.log(
      "nativePrice",
      toFixedNumber(nativePrice),
      props?.datas?.earnings
    );
    setNativePrice(toFixedNumber(nativePrice));
    const earningsBusd =
      props?.datas?.earnings / 10 ** 18
        ? (props?.datas?.earnings / 10 ** 18) * toFixedNumber(nativePrice)
        : 0;
    console.log("earningsBusd", toFixedNumber(earningsBusd));
    setEarningsBusd(earningsBusd);
    const totalLiquidity = new BigNumber(
      props?.datas?.lpTotalInQuoteToken
    ).times(props?.datas?.quoteTokenPriceBusd);
    console.log("totalLiquidity", totalLiquidity.toString());
    setLiquidity(totalLiquidity.toString());
  };

  const enable = async () => {
    let qureyString = `Approve ${props?.datas?.lpSymbol}`;
    let result = await ApproveFarms(props?.datas?.lpAddress, qureyString);
    console.log("enable", result);
    if (result?.status == true) {
      setCheckApprove(true);
    }
  };

  const stakedAmounts = async () => {
    let result = await UserDeposits(props?.datas?.pid);
    console.log("amount", result?.amount);
    setStakedAmount(result?.amount);
  };

  useEffect(() => {
    stakedAmounts();
  },[]);

  const Approvecheck = async (address, value) => {
    var result = await CheckIsApproved(address, value);

    setCheckApprove(result);
    console.log("setCheckApprove", result);
  };

  useEffect(() => {
    if (getWalletAddress()) {
      Approvecheck(props?.datas?.lpAddress, value);
    }
  }, [props?.datas?.lpAddress, value]);

  var obj = {
    data: props?.datas,
  };

  const Harvesting = async () => {
    setPending(true);
    console.log(
      "parseFloat(unstakeValue*10**18).toFixed(0) : ",
      parseFloat(props?.datas?.earnings * 10 ** 18).toFixed(0)
    );
    var res = await Deposit(props?.datas?.pid, 0);
    console.log("res", res);
    if (res == true) {
      toast.success(
        `Your ${props?.datas?.lpSymbol} earnings have also been harvested to your wallet!`
      );
      //   props.onHide()
      setPending(false);
    } else {
      toast.error("Try Again!");
    }
  };

  return (
    <>
      <div className={`${cascading.farmcard}`}>
        <div className={`${cascading.toprow}`}>
          <div className={`${cascading.leftimgsec}`}>
            <img
              src={fileObjectUrl(token?.image)}
              alt="img"
              className={`img-fluid ${cascading.fromimg}`}
            />
            <img
              src={fileObjectUrl(quoteToken?.image)}
              alt="img"
              className={`img-fluid  ${cascading.toimg}`}
            />
          </div>
          <div className={`${cascading.rightsec}`}>
            <p className={`${cascading.coinname}`}>
              {props?.datas?.tokenSymbol} - {props?.datas?.quoteTokenSymbol}{" "}
            </p>
            <div className={`${cascading.labelsec}`}>
              <p className={`${cascading.corelabel}`}>
                <svg
                  viewBox="0 0 24 24"
                  fill="#fff"
                  width="18px"
                  color="secondary"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sc-bdnxRM emNzIQ"
                >
                  <path d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM9.38 16.01L7 13.61C6.61 13.22 6.61 12.59 7 12.2L7.07 12.13C7.46 11.74 8.1 11.74 8.49 12.13L10.1 13.75L15.25 8.59C15.64 8.2 16.28 8.2 16.67 8.59L16.74 8.66C17.13 9.05 17.13 9.68 16.74 10.07L10.82 16.01C10.41 16.4 9.78 16.4 9.38 16.01Z"></path>
                </svg>{" "}
                Core
              </p>
              <p
                className={`${cascading.multilabel} d-flex justify-content-center align-items-center`}
              >
                {props?.datas?.multiplier}{" "}
              </p>
            </div>
          </div>
        </div>
        <div className={`${cascading.detaillist}`}>
          {console.log("APR", parseFloat(props?.datas?.apy))}
          <p className={`${cascading.leftlabel}`}>APR:</p>
          <p
            className={`${cascading.rightlabel} ${cascading.calculatorbtn} d-flex align-items-center gap-2`}
            onClick={() => setcalshow(true)}
          >
            {parseFloat(props?.datas?.apy) !== Infinity
              ? parseFloat(props?.datas?.apy).toLocaleString("en-Us")
              : 0}
            %{" "}
            <div className={`${cascading.labelIconBg} `}>
              {" "}
              <AiOutlineCalculator />
            </div>
          </p>
        </div>
        <div className={`${cascading.detaillist}`}>
          <p className={`${cascading.leftlabel}`}>Earn:</p>
          <p className={`${cascading.rightlabel}`}>
            {props?.datas?.rewardTokenSymbol[0]} + Fees
          </p>
        </div>
        <div className={`${cascading.harvestsec}`}>
          <p className={`${cascading.label} mb-0`}>
            {props?.datas?.rewardTokenSymbol[0]} EARNED
          </p>
          <div className={`${cascading.harvestrow}`}>
            <div>
              {/* <p className={`${cascading.label} mb-0`}>
                {parseFloat(props?.datas?.earnings / 10 ** 18).toFixed(4)}
              </p>
              <p className={`${cascading.labelsm}`}>
                ~{parseFloat(earningsBusd).toFixed(14)}{" "}
                {props?.datas?.rewardTokenSymbol[0]}
              </p> */}
               <p className={`${cascading.label} ${cascading.brakvaltext} mb-0`}>{props?.datas?.earnings != 0 ? roundToSignificant(props?.datas?.earnings / 10 ** 18,6) : 0}</p>
                            <p className={`${cascading.labelsm}`}>~{earningsBusd!=0 ? roundToSignificant(earningsBusd,6) : 0} USD</p>
              
            </div>
            <div>
              {console.log(
                "button",
                checkApprove == false || stakedAmount == 0
              )}
              <button
                className={`btn ${cascading.harvestbtn}`}
                disabled={
                  checkApprove == false ||
                  stakedAmount == 0 ||
                  props?.datas?.earnings == 0
                }
                onClick={() => {
                  Harvesting();
                }}
              >
                Harvest
              </button>
            </div>
          </div>
        </div>
        <div className={`${cascading.btnsec}`}>
          {userdetail?.walletStatus == "connect" &&
          !isEmpty(getWalletAddress()) ? (
            checkApprove == false ? (
              <>
                <p className={`${cascading.btnlabel} mt-3`}>
                  {props?.datas?.lpSymbol} STAKED
                </p>
                <button
                  className={`btn ${cascading.walletbtn}`}
                  onClick={() => {
                    enable();
                  }}
                  disabled={props?.status == "inactive"}
                >
                  Enable Contract
                </button>
              </>
            ) : (
              <>
                <div className={`${cascading.harvestsec} mt-4`}>
                  <p className={`${cascading.label} mb-1`}>
                    {props?.datas?.lpSymbol} STAKED
                  </p>
                  <div className={`${cascading.harvestrow}`}>
                    <div>
                    

<p className={`${cascading.label} ${cascading.brakvaltext} mb-1`}>{stakedAmount !=0 ? roundToSignificant(stakedAmount / 10 ** 18,1) : 0}</p>
                                            <p className={`${cascading.labelsm} mb-0`}>~{stakedUSDvalue!= 0 ? roundToSignificant(stakedUSDvalue, 6) : 0} USD</p>
                    </div>
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <button
                          className={`btn me-3 ${cascading.btnicon}`}
                          onClick={() => {
                            setUnStakemodal(true);
                          }}
                          disabled={props?.status == "inactive"}
                        >
                          <FaMinus />
                        </button>
                        <button
                          className={`btn ${cascading.btnicon}`}
                          onClick={() => {
                            setStakemodal(true);
                          }}
                          disabled={props?.status == "inactive"}
                        >
                          <FaPlus />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            <button
              className={`btn ${cascading.walletbtn}`}
              onClick={() => setShowmodal(true)}
            >
              Connect Wallet
            </button>
          )}
        </div>

        <div className={`${cascading.extradetailsec}`}>
          {!detailshow ? (
            <p
              className={`${cascading.label}`}
              onClick={() => setDetailshow(!detailshow)}
            >
              Details <FaAngleDown />
            </p>
          ) : (
            <p
              className={`${cascading.label}`}
              onClick={() => setDetailshow(!detailshow)}
            >
              Hide <FaAngleUp />
            </p>
          )}
          {detailshow && (
            <div className={`${cascading.detailrow}`}>
              {console.log(
                "Total_Total_",
                parseFloat(liquidity),
                toFixedNumber(parseFloat(liquidity).toFixed(15))
              )}
              <div className={`${cascading.listrow}`}>
                <p>Total Liquidity</p>
                {/* <p>$ {toFixedNumber(parseFloat(liquidity))}</p> */}
                <p>$ {liquidity != 0 ? roundToSignificant(liquidity, 6) :0}</p>
              </div>
              {/* <Link className={`${cascading.contractlink}`} to="/">View Contract <FaExternalLinkAlt fill="#FFF" /></Link> */}
              <button
                className={`${cascading.declabel}`}
                onClick={() => {
                  window.open(
                    `${CHAINS[GetChainIndex()].Testnet}/${
                      props?.datas?.lpAddress
                    }`,
                    "_blank"
                  );
                }}
                target="_blank"
              >
                View Contract
                <FaExternalLinkAlt className="ms-2" />
              </button>
            </div>
          )}
        </div>
      </div>
      {showmodal && (
        <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />
      )}
      {calshow && (
        <Calculatormodal
          show={calshow}
          isFarm={true}
          multiplier={props?.datas?.multiplier}
          address={props?.datas?.lpAddress}
          symbol={props?.datas?.lpSymbol}
          apy={props?.datas?.apy}
          earningTokenPrice={nativePrice}
          stakingTokenPrice={stakedUSDvalue}
          rewardToken={props?.datas?.rewardTokenSymbol[0]}
          onHide={() => setcalshow(false)}
        />
      )}
      {stakemodal && (
        <Stakemodal
          show={stakemodal}
          farms={obj}
          onHide={() => setStakemodal(false)}
        />
      )}
      {unStakemodal && (
        <Unstakemodal
          show={unStakemodal}
          farms={obj}
          onHide={() => setUnStakemodal(false)} stakedAmount={stakedAmount}
        />
      )}
    </>
  );
}
