import React, { useEffect, useState } from "react";

import cascading from "../assests/css/buyticketstyle.module.css";
import { FaRegQuestionCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { ApproveToken, BuyTickets, GetallowanceAmount, allowanceAmount, checkIsApproved, getAccount, getCakeAddress, getLotteryInfo, getOperatorTreasuryInjectorAddress, getTokenBalance } from "../hooks/useLottery";
import { getWalletAddress } from "../lib/localStorage";
import { Logger, serializeTransaction } from "ethers/lib/utils";
import { LotterytokenSymbol } from "../config/lottery";
import toast from "react-hot-toast";
import { iconTheme, position, style } from "../hooks/useToast";
import Yourwallet from "./yourwallet";
import { sleep } from "../config/tokens";



export default function Buyticketmodal(props) {
  // state
  const [buyticketmodal, setBuyticketmodal] = useState(true);
  const [LotteryAmount, setLotteryAmount] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [CakeAddress, setCakeAddress] = useState("");
  const [accountInfo, setaccountInfo] = useState(sessionStorage.getItem("accountInfo"))
  const [CakeAddressBalance, setCakeAddressBalance] = useState(0);
  const [ValidateError, setValidateError] = useState({})
  const [youwillget, setYouWillGet] = useState(0)
  const [Allowance, setAllowance] = useState(0)
  const [lotteryPrice, setlotteryPrice] = useState(0)
  const [ticketNumber, setticketNumber] = useState("")
  const [isCofirm, setisConfirm] = useState(false)  
  const [isView, setisView] = useState(false)
  const [isApproveClick, setisApproveClick] = useState(false)
  const [isQuantity, setisQuantity] = useState(false)
  const [ismaxenable,setismaxenable] = useState(false)
  const [isAllowance,setisAllowance] = useState(false)
  const [ismax, setismax] = useState(false);







  useEffect(() => {
    getLotteryData();
    checkIsApproval()
  }, [isApproved,Allowance,isAllowance])

  console.log("initial_ticketNumber", ticketNumber)

  const getLotteryData = async () => {
    var token = await getCakeAddress();
    setCakeAddress(token);
    var tokenBalance = await getTokenBalance(accountInfo);
    setCakeAddressBalance(parseFloat(tokenBalance / 10 ** 18).toFixed(3))


    var lotteryinfo = await getLotteryInfo(accountInfo);
    setlotteryPrice(parseFloat(lotteryinfo?.viewcurrentLottery?.priceTicketInCake) / 10 ** 18);
  }


  const checkIsApproval = async () => {
    var token = await getCakeAddress();
    const approval = await checkIsApproved(getWalletAddress(), token);
    setIsApproved(approval);
    const allowanceamount = await GetallowanceAmount(getWalletAddress(), token);
    console.log("Allowance>>>>>", allowanceamount,parseInt(allowanceamount) / 10 ** 18);
    setAllowance(parseInt(allowanceamount) / 10 ** 18);
    var data = parseInt(allowanceamount) / 10 ** 18;
    console.log("Allowance>>>>>",data,LotteryAmount,data > parseInt(LotteryAmount));

    if(parseInt(data) > parseInt(LotteryAmount)){
      setisAllowance(true)
    }
    if(LotteryAmount === ""){
      setisAllowance(false) 
    }
  

  }


  const Approve = async () => {
    if ( LotteryAmount == "") {
      ValidateError.LotteryAmount = "Please enter the amount";
      setValidateError(ValidateError);

    }else {
      // if(accountInfo){
        setisApproveClick(true)
        await ApproveToken(accountInfo, CakeAddress,youwillget.toString())
        setIsApproved(true);
        setisAllowance(true) 

        setismaxenable(true)
        setisApproveClick(false)
        setisQuantity(true)
        if(parseInt(Allowance) > parseInt(LotteryAmount)){
           setisAllowance(true) 
          }

     
     
    }
  }

  const max = async () => {
    setismax(true)
    let myallowance = parseInt(Allowance);

    let MaxQuanty = 100;
    if(parseInt(CakeAddressBalance) >= MaxQuanty){
      let calc = await PriceCalculate({ LotteryAmount: MaxQuanty });
      let estimate = calc * 10 ** 18;
     
      setLotteryAmount(parseInt(MaxQuanty))
      if (estimate < myallowance) {
        setisConfirm(false)

        setIsApproved(true);
        setisAllowance(true)


    }
  }
    else{

      let Quantity =  parseInt(CakeAddressBalance) 
      let calc = await maxPriceCalculate({ LotteryAmount: Quantity });
      console.log("calccalc",calc);
      let estimate = calc * 10 ** 18;
      setLotteryAmount(parseInt(calc))
      if (estimate < myallowance) {
        setisConfirm(false)

        setIsApproved(true);
        setisAllowance(true)

    }

    

      

    }

  }

  const PriceCalculate = async (data = {}) => {
    var price = typeof data.LotteryAmount != "undefined" ? data.LotteryAmount : LotteryAmount;
    // if(lotteryPrice == 0) {
    //   setYouWillGet(0);
    //   return 0;

    //  }
    var totamount = (lotteryPrice * price);
    console.log("lotteryPrice>>>>>>,", lotteryPrice, price);
    setYouWillGet(Number(totamount));
    return totamount;

  };


  const maxPriceCalculate = async (data = {}) => {
    var price = typeof data.LotteryAmount != "undefined" ? data.LotteryAmount : LotteryAmount;
    var totamount = ( parseFloat(price) / parseFloat(lotteryPrice));
    console.log("totamount",totamount,lotteryPrice,parseInt(totamount) * parseFloat(lotteryPrice));
    setYouWillGet(parseInt(totamount) * parseFloat(lotteryPrice));
    return totamount;

  };


  const inputChange = async (e) => {


    const decimalPattern = /^\d+$/;
    if (!(e.target.value == '' || decimalPattern.test(e.target.value))) {
      setisConfirm(false)  
      setisView(false) 
     }else{


    // debugger

    if (
      e &&
      e.target &&
      e.target.name
    ) {

      var value = e.target.value;

    
  


      switch (e.target.name) {
        case "LotteryAmount":
       
    
          if (value != "" && isNaN(value) == false && value > 0) {
            setLotteryAmount(value);
            ValidateError.LotteryAmount = "";
            let calc = await PriceCalculate({ LotteryAmount: value });
            setValidateError(ValidateError);
            let estimate = calc * 10 ** 18;
            let myallowance = parseFloat(Allowance);
            if (estimate < myallowance) {
              setIsApproved(true);

            }
        
            var ticketArray = [];

            for (var i = 0; i < value; i++) {
              var randomNo = Math.floor(Math.random() * (1999999 - 1000000 + 1));
              var randomNo =
                parseFloat(randomNo.toString().split("").reverse().join("")) *
                Math.sign(randomNo);
              var final = randomNo + 1000000;
              ticketArray.push(final);
              setticketNumber(ticketArray)
              setisConfirm(false)
              setisView(false)
              setisApproveClick(false)
            }
            console.log("finalfinal", final);

          

          } else {
        let calc = await PriceCalculate({ LotteryAmount: 0 });

            ValidateError.LotteryAmount = "Enter Valid number";
            setValidateError(ValidateError);
            setLotteryAmount(value);
            setisConfirm(true)
            setisView(true)
            setisApproveClick(true)
          }
          break;
      }
      // window.$('#Validation_PlaceABid').click();
      if (value > 100) {
        
        ValidateError.LotteryAmount = "Please enter equal or below 100";
        setValidateError(ValidateError);
        setisConfirm(true)
        setisView(true)
        setisApproveClick(true)

        let calc = await PriceCalculate({ LotteryAmount: 0 });
        return false
      }

      if(parseInt(Allowance) >= parseInt(value)){
        setisAllowance(true)
      }else if(value === ""){
        setisAllowance(false) 
      }else{
        setisAllowance(false) 
      }
      if (value < 1) {

        let calc = await PriceCalculate({ LotteryAmount: 0 });

        ValidateError.LotteryAmount = "Please enter greater than 1";
        setValidateError(ValidateError);
        setisConfirm(true)
        setisView(true)
        setisApproveClick(true)
        return false
      }


      else  if(parseInt(value) > parseInt(CakeAddressBalance) ){
        let calc = await PriceCalculate({ LotteryAmount: 0 });

        ValidateError.LotteryAmount = "you don't have token balance in your account";
        setValidateError(ValidateError);
                setisConfirm(true)
                setisView(true)
                setisApproveClick(true)

        return false

      }

      
      else  if(parseInt(youwillget) > parseInt(CakeAddressBalance) ){
        let calc = await PriceCalculate({ LotteryAmount: 0 });

        ValidateError.LotteryAmount = "you don't have balance in your account";
        setValidateError(ValidateError);
                setisConfirm(true)
                setisView(true)
                setisApproveClick(true)


        return false

      }
     
    }
  }
  };






  const buyTickets = async () => {

console.log("LotteryAmountLotteryAmount",typeof LotteryAmount,LotteryAmount);
    var ticketArray = [];

    for (var i = 0; i < LotteryAmount; i++) {
      var randomNo = Math.floor(Math.random() * (1999999 - 1000000 + 1));
      var randomNo =
        parseFloat(randomNo.toString().split("").reverse().join("")) *
        Math.sign(randomNo);
      var final = randomNo + 1000000;
      ticketArray.push(final);
      setticketNumber(final)

    }
    setisConfirm(true)
    console.log("ticketArray",ticketArray);
    var buynow = await BuyTickets(ticketArray, accountInfo)
  }

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={buyticketmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
          backdrop="static"
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle} mb-0`}>Buy Tickets</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >
            <p className={`${cascading.innerhead}`}>Buy:</p>

            <div className={`${cascading.inputsec} `}>

              <div className="d-flex align-items-end justify-content-between gap-3">

                <div>
                  <div>
                    <input
                      type="text"
                      className={`from-control`}
                      id="LotteryAmount"
                      name="LotteryAmount"
                      readOnly={isQuantity}
                      
                      aria-describedby="emailHelp"
                      placeholder="1"
                      value={LotteryAmount}
                      onChange={inputChange}

                    />
                  </div>

                  <div className={`${cascading.balancerow}`}>


                    <p className={`${cascading.inputlabel} mb-0 mt-2`}>
                      -{youwillget.toFixed(3)} {LotterytokenSymbol}
                    </p>

                  </div>
                </div>

                <div>
                  <button onClick={() => max()} disabled={ismaxenable} className={`btn me-2 ${cascading.buttonstyle_ul} ${cascading.buttonstyle_ul_new}`}>
                    Max
                  </button>
                </div>

              </div>



            </div>
            {ValidateError.LotteryAmount && (
              <span className="text-danger">
                <br />
                {ValidateError.LotteryAmount}
              </span>
            )}

            <p className={`${cascading.balancetext}`}>{LotterytokenSymbol} Balance: {CakeAddressBalance}</p>

            {/* <ul className={`${cascading.button_ul}`}>
              <li>
                <button className={`btn me-2 ${cascading.buttonstyle_ul}`}>
                  1
                </button>
              </li>
              <li>
                <button className={`btn me-2 ${cascading.buttonstyle_ul}`}>
                  Max
                </button>
              </li>

            </ul> */}


            <div className='row mt-3'>
              <div className='col-8 col-md-7'>
                <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Cost {`(${LotterytokenSymbol})`}</p>
              </div>
              <div className='col-4 col-md-5'>
                <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>{youwillget.toFixed(3)} {LotterytokenSymbol}</p>
              </div>
            </div>

            {/* <div className='row mt-3'>
              <div className='col-8 col-md-7'>
                <p className={`mb-0 ${cascading.innerheadwhitesm}`}>0% Bulk discount
                  <OverlayTrigger
                    className={`${cascading.tooltip_style}`}
                    key="bottom"
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        <p className="mb-2">Buying multiple tickets in a single transaction gives a discount. The discount increases in a linear way, upto the maximum of 100 tickets:</p>
                        <p className="mb-1">2 tickets: 0.05%</p>
                        <p className="mb-1">50 tickets: 2.45%</p>
                        <p className="mb-1">100 tickets: 4.95%</p>

                      </Tooltip>
                    }
                  >
                    <Button className={`${cascading.tooltip_button} ${cascading.tooltip_button_blk}`}>
                      <FaRegQuestionCircle />
                    </Button>
                  </OverlayTrigger></p>
              </div>
              <div className='col-4 col-md-5'>
                <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>~0 {LotterytokenSymbol}</p>
              </div>
            </div> */}

            <hr />

            <div className='row mt-3'>
              <div className='col-8 col-md-7'>
                <p className={`mb-0 ${cascading.innerheadwhitesm}`}>You pay</p>
              </div>
              <div className='col-4 col-md-5'>
                <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>{youwillget.toFixed(3)} {LotterytokenSymbol}</p>
              </div>
            </div>
            {console.log("Allowance",isApproved, isAllowance )}            
            {
                          
                          // isApproved && parseFloat(Allowance) / 10 ** 18 > parseFloat(LotteryAmount) ?

            isApproved && isAllowance  ?
              
                <button
                  className={`btn mt-4 ${cascading.connectwalletbtn}`}
                  type="button"
                  disabled={isCofirm}
                  onClick={() => buyTickets()}
                >
                  Confirm
                </button> : <>

                {/* {parseFloat(Allowance) < parseFloat(LotteryAmount) ?  */}
                <> <button
                className={`btn mt-4 ${cascading.connectwalletbtn}`}
                type="button"
                disabled={isApproveClick}
                // onClick={props.onHide}
                onClick={() => Approve()}
              >
                Approve
              </button></> 
              {/* : <></> } */}
                 
                  
                  </>}

            {(isApproved == true) && isAllowance && parseFloat(LotteryAmount) > 0 ?
              <button
                className={`btn mt-2 ${cascading.cancelbtn}`}
                type="button"
                disabled={isView}

                onClick={() => { props.onShowEdit(); props.TicketNumberData(ticketNumber); props.LotteryAmount(youwillget) }}
              >
                View Ticket Numbers
              </button> : <></>}

            <p className={`${cascading.head} mt-4`}>

              "Buy Instantly" chooses random numbers, with no duplicates among your tickets. Prices are set before each round starts, equal to $5 at that time purchases are final.
            </p>
          </Modal.Body>
        </Modal>
      </div>

    </>
  );
}
