// import lib
import {isEmpty} from '../lib/isEmpty';

export const validationAddress = (value) => {
    let errors = {};
    
    
    
    if (isEmpty(value.Operator)) {
        errors.Operator = "Please enter operator address"
    }
    if (isEmpty(value.Injector)) {
        errors.Injector = "Please enter injector address"
    }
    if (isEmpty(value.Treasury)) {
        errors.Treasury = "Please enter treasury address"
        
    } 
    console.log("validationAddress",errors);
   
    return errors;
}



export const AddLotteryvalidation = value => {
    console.log("AddLotteryvalidation",value);
    let errors = {};

    if (value.endtime == "NaN") {
        errors.endtime = "Please enter End Time"
    }

    if (isEmpty(value.endtime)) {
        errors.endtime = "Please enter End Time"
    }
    if (isEmpty(value.ticketPice)) {
        errors.ticketPice = "Please Enter Ticket Rate"
    }
   
    if (isEmpty(value.reward1)) {
        errors.reward1 = "Please Enter reward"
    } 
    if (isEmpty(value.reward2)) {
        errors.reward2 = "Please Enter reward"
    }
    if (isEmpty(value.reward3)) {
        errors.reward3 = "Please Enter reward"
    }
    if (isEmpty(value.reward4)) {
        errors.reward4 = "Please Enter reward"
    }
    if (isEmpty(value.reward5)) {
        errors.reward5 = "Please Enter reward"
    }
    if (isEmpty(value.reward6)) {
        errors.reward6 = "Please Enter reward"
    }

    console.log("AddLotteryvalidation",errors);
    return errors;
}
