import React, { useState, useEffect } from "react";
import cascading from "../assests/css/farmliststyle.module.css";
import logo from "../assests/images/cardlogo.png";
import Walletmodal from "../modals/walletmodal";
import { FaAngleDown } from "react-icons/fa6";
import {
  FaAngleUp,
  FaExternalLinkAlt,
  FaRegQuestionCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { AiOutlineCalculator } from "react-icons/ai";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Calculatormodal from "../modals/calculatormodal";
import Stakemodal from "../modals/stakemodal";
import Unstakemodal from "../modals/unStakemodal";
import toast, { Toaster } from "react-hot-toast";
import BigNumber from "bignumber.js";
import { REFERRER_ADDRESS } from "../config/env";

import { Accordion, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

//hooks
import { getTokens, GetCurrencyBalance, multicall } from "../hooks/useTokens";
import {
  ApproveFarms,
  CheckIsApproved,
  Harvest,
  Deposit,
} from "../hooks/useMasterChef";
import { UserDeposits } from "../hooks/useStake";
import { UseLpTokenPrice, getTokenPricesFromFarm } from "../hooks/useFarms";
import { GetChainIndex } from "../hooks/useWeb3";

//lib
import fileObjectUrl from "../lib/img";
import { toFixedNumber } from "../lib/FixedNumber";
import { isEmpty } from "../lib/isEmpty";
import { getWalletAddress } from "../lib/localStorage";
import {roundToSignificant} from '../hooks/useCommon'

import { CHAINS } from "../config/env";

export default function Farmlist(props) {
  const [showmodal, setShowmodal] = useState(false);
  const [stakemodal, setStakemodal] = useState(false);
  const [unStakemodal, setUnStakemodal] = useState(false);

  const [detailshow, setDetailshow] = useState(false);
  const [calshow, setcalshow] = useState(false);
  const [token, setToken] = useState(false);
  const [quoteToken, setQuoteToken] = useState(false);
  const [isenable, setIsenable] = useState(true);
  const [stakedAmount, setStakedAmount] = useState();
  const [checkApprove, setCheckApprove] = useState(false);
  const [value, setValue] =
    useState(
      115792089237316195423570985008687907853269984665640564039457584007913129639935
    );
  const [pending, setPending] = useState(false);
  const [earningsBusd, setEarningsBusd] = useState();
  const [liquidity, setLiquidity] = useState();
  const [stakedUSDvalue, setStakedUSDvalue] = useState();
  const [nativePrice, setNativePrice] = useState();

  const userdetail = useSelector((state) => state.admin);
  const { datas, status } = props;
  // useEffect(() => {
  //   console.log("props_dataslist", props?.datas, props?.status);
  //   let token1 = getTokens()?.find(
  //     (val) =>
  //       val?.address?.toLowerCase() == props?.datas?.tokenAddress?.toLowerCase()
  //   );
  //   console.log("token1", token1);
  //   setToken(token1);
  //   let token2 = getTokens()?.find(
  //     (val) =>
  //       val?.address?.toLowerCase() ==
  //       props?.datas?.quoteTokenAddress?.toLowerCase()
  //   );
  //   console.log("token2", token2);
  //   setQuoteToken(token2);
  //   localFn();
  // }, [datas]);

  useEffect(() => {
    console.log("props_dataslist", props?.datas, props?.status)
    let token1 = getTokens()?.find((val) => (val?.address?.toLowerCase() == props?.datas?.tokenAddress?.toLowerCase()));
    console.log("token1", token1)
    setToken(token1)
    let token2 = getTokens()?.find((val) => (val?.address?.toLowerCase() == props?.datas?.quoteTokenAddress?.toLowerCase()));
    console.log("token2", token2)
    setQuoteToken(token2)
    localFn()
}, [datas])


  useEffect(() => {
    stakedUSD();
  },[]);

  const stakedUSD = () => {
    let value = UseLpTokenPrice(props?.datas?.lpSymbol);
    console.log("stakedUSD", new BigNumber(value).toString());
    // setStakedUSDvalue(new BigNumber(value).toString());
    setStakedUSDvalue(new BigNumber(value).times(stakedAmount).toString())

  };

  // const localFn = () => {
  //   var price = localStorage.getItem("Farms");
  //   price = JSON.parse(price);
  //   console.log("price_price", price);
  //   let nativePrice = price?.find((val) => val?.pid == 1).tokenPriceBusd;
  //   console.log(
  //     "nativePrice",
  //     toFixedNumber(nativePrice),
  //     props?.datas?.earnings
  //   );
  //   setNativePrice(toFixedNumber(nativePrice));
  //   const earningsBusd =
  //     props?.datas?.earnings / 10 ** 18
  //       ? (props?.datas?.earnings / 10 ** 18) * toFixedNumber(nativePrice)
  //       : 0;
  //   console.log("earningsBusd", toFixedNumber(earningsBusd));
  //   setEarningsBusd(earningsBusd);
  //   const totalLiquidity = new BigNumber(
  //     props?.datas?.lpTotalInQuoteToken
  //   ).times(props?.datas?.quoteTokenPriceBusd);
  //   console.log("totalLiquidity", totalLiquidity.toString());
  //   setLiquidity(totalLiquidity.toString());
  // };

  const localFn = () => {
    var price = localStorage.getItem("Farms")
    price = JSON.parse(price)
    console.log("price_price", price)
    let nativePrice = price?.find((val) => (val?.pid == 1)).tokenPriceBusd
    console.log("nativePrice", toFixedNumber(nativePrice), props?.datas?.earnings)
    setNativePrice(toFixedNumber(nativePrice))
    const earningsBusd = props?.datas?.earnings / 10 ** 18 ? props?.datas?.earnings / 10 ** 18 * toFixedNumber(nativePrice) : 0
    console.log("earningsBusd", toFixedNumber(earningsBusd))
    setEarningsBusd(earningsBusd)
    const totalLiquidity = new BigNumber(props?.datas?.lpTotalInQuoteToken).times(props?.datas?.quoteTokenPriceBusd)
    console.log("totalLiquidity", (totalLiquidity).toString())
    setLiquidity((totalLiquidity).toString())

}

  const enable = async () => {
    let result = await ApproveFarms(props?.datas?.lpAddress);
    console.log("enable", result);
    if (result?.status == true) {
      setCheckApprove(true);
    }
  };

  const stakedAmounts = async () => {
    let result = await UserDeposits(props?.datas?.pid);
    console.log("amount", result?.amount);
    setStakedAmount(result?.amount);
  };

  useEffect(() => {
    stakedAmounts();
  },[]);

  const Approvecheck = async (address, value) => {
    var result = await CheckIsApproved(address, value);

    setCheckApprove(result);
    console.log("setCheckApprove", result);
  };

  useEffect(() => {
    if (getWalletAddress()) {
      Approvecheck(props?.datas?.lpAddress, value);
    }
  }, [props?.datas?.lpAddress, value]);

  var obj = {
    data: props?.datas,
  };

  const Harvesting = async () => {
    setPending(true);
    console.log(
      "parseFloat(unstakeValue*10**18).toFixed(0) : ",
      parseFloat(props?.datas?.earnings * 10 ** 18).toFixed(0)
    );
    var res = await Deposit(props?.datas?.pid, 0);
    console.log("res", res);
    if (res == true) {
      toast.success(
        `Your ${props?.datas?.lpSymbol} earnings have also been harvested to your wallet!`
      );
      //   props.onHide()
      setPending(false);
    } else {
      toast.error("Try Again!");
    }
  };

  var e = props.datas;

  return (
    <>
      <Accordion
        defaultActiveKey="0"
        className={`${cascading.accordion_style} `}
      >
        {/* {props.datas.map((e, i) => ( */}

        <Accordion.Item
          eventKey={props?.index}
          className={`${cascading.accordion_item}`}
        >
          <Accordion.Header className={`${cascading.accordion_head}`}>
            <div className={`${cascading.headrow} ${cascading.headrowgrid}`}>
              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img
                    src={fileObjectUrl(token?.image)}
                    alt="img"
                    className={`img-fluid ${cascading.fromimg}`}
                  />
                  <img
                    src={fileObjectUrl(quoteToken?.image)}
                    alt="img"
                    className={`img-fluid  ${cascading.toimg}`}
                  />
                </div>
                <p className={`${cascading.coinname}`}>
                  {props?.datas?.tokenSymbol} - {props?.datas?.quoteTokenSymbol}
                </p>
              </div>
              <div className={`${cascading.listsec}`}>
                <p className={`${cascading.labelname}`}>Earned</p>
                <p className={`${cascading.labelvalue}`}>
                  {parseFloat(props?.datas?.earnings / 10 ** 18).toFixed(4)}
                </p>
              </div>
              <div className={`${cascading.listsec}`}>
                <p className={`${cascading.labelname}`}>APR</p>
                <p
                  className={`${cascading.labelvalue} ${cascading.calculatorbtn} d-flex align-items-center gap-2`}
                  onClick={() => setcalshow(true)}
                >
                  {parseFloat(props?.datas?.apy) !== Infinity
                    ? parseFloat(props?.datas?.apy).toLocaleString("en-Us")
                    : 0}
                  % <AiOutlineCalculator />
                </p>
              </div>

              <div
                className={`d-none d-lg-flex ${cascading.listsec} ${cascading.liqsec}`}
              >
                <div>
                  <p className={`${cascading.labelname}`}>Liquidity</p>
                  <p
                    className={`${cascading.labelvalue} ${cascading.labelvaluebrak}`}
                  >
                    $ {toFixedNumber(liquidity)}
                  </p>
                </div>
                <div>
                  <OverlayTrigger
                    className={`${cascading.tooltip_style}`}
                    key="right"
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-right`}>
                        Total value of the funds in this farm’s liquidity pool
                      </Tooltip>
                    }
                  >
                    <Button className={`${cascading.tooltip_button}`}>
                      <FaRegQuestionCircle />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
              <div
                className={`d-none d-lg-block ${cascading.listsec} ${cascading.multisec}`}
              >
                <p className={`${cascading.labelname}`}>Multiplier</p>
                <div className={`${cascading.multitooltip}`}>
                  <p className={`${cascading.labelvalue} `}>
                    {props?.datas?.multiplier}{" "}
                  </p>
                  <OverlayTrigger
                    className={`${cascading.tooltip_style}`}
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        The Multiplier represents the proportion of USDTSwap
                        rewards each farm receives, as a proportion of the
                        USDTSwap produced each block. This amount is already
                        included in all APR calculations for the farm.
                      </Tooltip>
                    }
                  >
                    <Button className={`${cascading.tooltip_button}`}>
                      <FaRegQuestionCircle />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
              <div className={`d-block d-lg-none ${cascading.detailist} `}>
                <p className={`${cascading.labelname}`}>Details</p>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className={`${cascading.accordion_body}`}>
            <div className={`row ${cascading.rowmarg}`}>
              <div className={`col-lg-2 mb-4 mb-lg-0 d-none d-lg-block`}>
                {/* <Link to="" className={`${cascading.contractlink}`}>View Contract <FaExternalLinkAlt fill="#FFF" /></Link> */}

                <button
                  className={`${cascading.declabel}`}
                  onClick={() => {
                    window.open(
                      `${CHAINS[GetChainIndex()].Testnet}/${
                        props?.datas?.lpAddress
                      }`,
                      "_blank"
                    );
                  }}
                  target="_blank"
                >
                  View Contract
                  <FaExternalLinkAlt className="ms-2" />
                </button>

                <p className={`${cascading.corelabel}`}>
                  <svg
                    viewBox="0 0 24 24"
                    fill="#000"
                    width="18px"
                    color="secondary"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sc-bdnxRM emNzIQ"
                  >
                    <path d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM9.38 16.01L7 13.61C6.61 13.22 6.61 12.59 7 12.2L7.07 12.13C7.46 11.74 8.1 11.74 8.49 12.13L10.1 13.75L15.25 8.59C15.64 8.2 16.28 8.2 16.67 8.59L16.74 8.66C17.13 9.05 17.13 9.68 16.74 10.07L10.82 16.01C10.41 16.4 9.78 16.4 9.38 16.01Z"></path>
                  </svg>{" "}
                  Core
                </p>
              </div>
              <div className={`col-sm-6 col-lg-5 mb-4 mb-lg-0`}>
                <div className={`${cascading.bordecard}`}>
                  <p className={`${cascading.cardlabel}`}>
                    <span className="me-2">
                      {props?.datas?.rewardTokenSymbol[0]}
                    </span>
                    EARNED
                  </p>
                  <div
                    className={`${cascading.harvestrow} ${cascading.harvestrowcol}`}
                  >
                    <div>
                      <p className={`${cascading.label}`}>
                        {parseFloat(props?.datas?.earnings / 10 ** 18).toFixed(
                          4
                        )}
                      </p>
                      <p className={`${cascading.labelsm}`}>
                        ~{parseFloat(earningsBusd).toFixed(14)}{" "}
                        {props?.datas?.rewardTokenSymbol[0]}
                      </p>
                    </div>
                    <div>
                      <button
                        className={`btn ${cascading.harvestbtn}`}
                        disabled={
                          checkApprove == false ||
                          stakedAmount == 0 ||
                          props?.datas?.earnings == 0
                        }
                        onClick={() => {
                          Harvesting();
                        }}
                      >
                        {" "}
                        Harvest
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-sm-6 col-lg-5 mb-4 mb-lg-0`}>
                <div className={`${cascading.bordecard}`}>
                  {/* <p className={`${cascading.cardlabel}`}> {userdetail.walletStatus == 'connect' ? "EARNED FARMING" : "STARTED FARMING"}</p> */}
                  {console.log(
                    userdetail?.walletStatus == "connect" &&
                      !isEmpty(getWalletAddress()),
                    "walletCheck_condition"
                  )}
                  {userdetail?.walletStatus == "connect" &&
                  !isEmpty(getWalletAddress()) ? (
                    checkApprove == false ? (
                      <>
                        <p className={`${cascading.btnlabel}`}>
                          {props?.datas?.lpSymbol} STAKED
                        </p>
                        <button
                          className={`btn ${cascading.walletbtn} ${cascading.enablebtn}`}
                          onClick={() => {
                            enable();
                          }}
                          disabled={props?.status == "inactive"}
                        >
                          Enable
                        </button>
                      </>
                    ) : (
                      <>
                        <p className={`mb-0 ${cascading.cardlabel}`}>
                          {props?.datas?.lpSymbol} STAKED
                        </p>
                        <div
                          className={`${cascading.harvestrow} ${cascading.harvestrowcol}`}
                        >
                          {/* <div>
                            <p className={`${cascading.label}`}>
                              {toFixedNumber(
                                parseFloat(stakedAmount / 10 ** 18).toFixed(10)
                              )}
                            </p>
                            <p className={`${cascading.labelsm}`}>
                              ~{parseFloat(stakedUSDvalue).toFixed(4)}{" "}
                              {props?.datas?.rewardTokenSymbol[0]}
                            </p>
                          </div> */}

<div>
                           <p className={`${cascading.label} ${cascading.brakvaltext}`}>{stakedAmount !=0 ? roundToSignificant(stakedAmount / 10 ** 18,1) : 0}</p>
                           <p className={`${cascading.labelsm}`}>~{stakedUSDvalue!= 0 ? roundToSignificant(stakedUSDvalue, 4) : 0} USD</p>
                                                    </div>
                          <div className="d-flex align-items-center justify-content-between">
                            {/* <button
                              className={`btn me-3 ${cascading.btnicon}`}
                              disabled={props?.status == "inactive"}
                              onClick={() => {
                                setUnStakemodal(true);
                              }}
                            > */}

<button className={`btn me-3 ${cascading.btnicon}`} disabled={props?.status == 'inactive' || stakedAmount == 0} onClick={() => { setUnStakemodal(true); }}>

                              <FaMinus />
                            </button>
                            <button
                              className={`btn ${cascading.btnicon}`}
                              disabled={props?.status == "inactive"}
                              onClick={() => {
                                setStakemodal(true);
                              }}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <button
                      className={`btn ${cascading.connectwalletbtn}`}
                      onClick={() => setShowmodal(true)}
                    >
                      {" "}
                      Connect Wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className={` d-block d-lg-none px-4`}>
              <div className={`${cascading.mblelist} mb-2`}>
                <p className={`${cascading.labelname}`}>Multiplier</p>
                <p className={`${cascading.labelvalue}`}>
                  {props?.datas?.multiplier}{" "}
                  <OverlayTrigger
                    className={`${cascading.tooltip_style}`}
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        The Multiplier represents the proportion of USDTSwap
                        rewards each farm receives, as a proportion of the
                        USDTSwap produced each block. This amount is already
                        included in all APR calculations for the farm.
                      </Tooltip>
                    }
                  >
                    <Button className={`${cascading.tooltip_button}`}>
                      <FaRegQuestionCircle fill="#000" />
                    </Button>
                  </OverlayTrigger>
                </p>
              </div>
              <div className={`${cascading.mblelist} mb-2`}>
                <p className={`${cascading.labelname}`}>Liquidity</p>
                <p
                  className={`${cascading.labelvalue} ${cascading.wordbreakval} ms-2`}
                >
                  $ {liquidity != 0 ? roundToSignificant(liquidity, 6) :0}
                  <OverlayTrigger
                    className={`${cascading.tooltip_style}`}
                    key="right"
                    placement="left"
                    overlay={
                      <Tooltip id={`tooltip-left`}>
                        Total value of the funds in this farm’s liquidity pool
                      </Tooltip>
                    }
                  >
                    <Button className={`${cascading.tooltip_button}`}>
                      <FaRegQuestionCircle fill="#000" />
                    </Button>
                  </OverlayTrigger>
                </p>
              </div>
              <div>
                {/* <Link to="" className={`${cascading.contractlink}`}>View Contract <FaExternalLinkAlt fill="#FFF" /></Link> */}

                <button
                  className={`${cascading.declabel}`}
                  onClick={() => {
                    window.open(
                      `${CHAINS[GetChainIndex()].Testnet}/${
                        props?.datas?.lpAddress
                      }`,
                      "_blank"
                    );
                  }}
                  target="_blank"
                >
                  View Contract
                </button>
                <FaExternalLinkAlt fill="#7645d9" />

                <p className={`${cascading.corelabel}`}>
                  <svg
                    viewBox="0 0 24 24"
                    fill="#000"
                    width="18px"
                    color="secondary"
                    xmlns="http://www.w3.org/2000/svg"
                    class="sc-bdnxRM emNzIQ"
                  >
                    <path d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM9.38 16.01L7 13.61C6.61 13.22 6.61 12.59 7 12.2L7.07 12.13C7.46 11.74 8.1 11.74 8.49 12.13L10.1 13.75L15.25 8.59C15.64 8.2 16.28 8.2 16.67 8.59L16.74 8.66C17.13 9.05 17.13 9.68 16.74 10.07L10.82 16.01C10.41 16.4 9.78 16.4 9.38 16.01Z"></path>
                  </svg>{" "}
                  Core
                </p>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        {/* ))} */}
      </Accordion>
      {showmodal && (
        <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />
      )}
      {calshow && (
        <Calculatormodal
          show={calshow}
          isFarm={true}
          multiplier={props?.datas?.multiplier}
          address={props?.datas?.lpAddress}
          symbol={props?.datas?.lpSymbol}
          apy={props?.datas?.apy}
          earningTokenPrice={nativePrice}
          stakingTokenPrice={stakedUSDvalue}
          rewardToken={props?.datas?.rewardTokenSymbol[0]}
          onHide={() => setcalshow(false)}
        />
      )}
      {stakemodal && (
        <Stakemodal
          show={stakemodal}
          farms={obj}
          onHide={() => setStakemodal(false)}
        />
      )}
      {/* {unStakemodal && (
        <Unstakemodal
          show={unStakemodal}
          farms={obj}
          onHide={() => setUnStakemodal(false)}
        />
      )} */}

{unStakemodal && <Unstakemodal show={unStakemodal} farms={obj} onHide={() => setUnStakemodal(false)} stakedAmount={stakedAmount} />}

    </>
  );
}
