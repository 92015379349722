import React, { useState, useRef, useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";

import Header from "../../common/header";
import Footer from "../../common/footer";

import cascading from "../../assests/css/Prediction.module.css";
import { AddLotteryvalidation } from "../../hooks/useValidation";
import { isEmpty } from "../../lib/isEmpty";
import toast from "react-hot-toast";
import { iconTheme, position, style } from "../../hooks/useToast";
import { GetCurrentLotteryID, StartLottery, StartLottry, ViewAllLottery, ViewCurrentLottery } from "../../hooks/useLottery";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



// Import Swiper React components



// import required modules


// Install Swiper modules

const Addlottery = () => {

  const [isConnectWallet, setIsConnectWallet] = useState(true);
  const [count, setCount] = useState(1)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Treasury, setTreasury] = useState("");
  const [accountInfo, setaccountInfo] = useState(sessionStorage.getItem("accountInfo"))
  const [reward1, setReward1] = useState("");
  const [reward2, setReward2] = useState("");
  const [reward3, setReward3] = useState("");
  const [reward4, setReward4] = useState("");
  const [reward5, setReward5] = useState("");
  const [reward6, setReward6] = useState("");
  const [endtime, setEndtime] = useState("")
  const [discount, setDiscount] = useState(0)
  const [ticketPrice, setticketPice] = useState("")
  const [fee, setFee] = useState(0)
  const [ValidateError, setValidateError] = useState({})
  const [allrecords, setrecords] = useState([])
  const [addButton, setaddButton] = useState(false)
  const [note,setNote] =useState("")
  const [startDate, setStartDate] = useState('');
  const [isadd,setisAdd] = useState(false)


  console.log('startdate', startDate)


  useEffect(() => {
    AllLottery()
  }, [])


  const AllLottery = async () => {

    var alllottey = await ViewAllLottery();

    var viewLotteryId = await ViewCurrentLottery(alllottey.length);
    var enddate = new Date(viewLotteryId.endTime * 1000);
    console.log("alllottey>>>>>", viewLotteryId);
    var lotteryStatus = viewLotteryId?.status == 0 && new Date(enddate * 1000) > new Date()
    var lotteryinfo = await GetCurrentLotteryID();
    if(lotteryinfo.status== 1 || lotteryinfo.status == 2){
      setaddButton(true)
      setisAdd(true)
      setNote("Previous Lottery is not closed")
    }

    console.log("lotteryinfo",lotteryinfo.status);

  }
  const inputChange = async (e) => {
    console.log("eeeee", e.target.value);
    const regex = /^\d*\.?\d*$/;


    // if (parseInt(e.target.value) == 0) {

    //   ValidateError.ticketPice = "Enter valid price";
    //   setValidateError(ValidateError);
    //   setaddButton(true)
    //   return false;
    // }
    // else 
    if(regex.test(e?.target?.value) || e?.target?.value == "" ||   e?.target?.value === ".")


      {
 
        setticketPice(e.target.value)
        setaddButton(false)
         }
  }



  const reward1inputChange = async (e) => {
    console.log("eeeee", e.target);

    const decimalPattern = /^\d+$/;
    if (e.target.value == '' || decimalPattern.test(e.target.value)) {
      setReward1(e.target.value)
    }
  }
  const reward2inputChange = async (e) => {
    console.log("eeeee", e.target);

    const decimalPattern = /^\d+$/;
    if (e.target.value == '' || decimalPattern.test(e.target.value)) {
      setReward2(e.target.value)
    }
  }
  const reward3inputChange = async (e) => {
    console.log("eeeee", e.target);

    const decimalPattern = /^\d+$/;
    if (e.target.value == '' || decimalPattern.test(e.target.value)) {
      setReward3(e.target.value)
    }
  }

  const reward4inputChange = async (e) => {
    console.log("eeeee", e.target);

    const decimalPattern = /^\d+$/;
    if (e.target.value == '' || decimalPattern.test(e.target.value)) {
      setReward4(e.target.value)
    }
  }
  const reward5inputChange = async (e) => {
    console.log("eeeee", e.target);

    const decimalPattern = /^\d+$/;
    if (e.target.value == '' || decimalPattern.test(e.target.value)) {
      setReward5(e.target.value)
    }
  }
  const reward6inputChange = async (e) => {
    console.log("eeeee", e.target);

    const decimalPattern = /^\d+$/;
    if (e.target.value == '' || decimalPattern.test(e.target.value)) {
      setReward6(e.target.value)
    }
  }
  const onSubmit = async () => {

    let endDiff = parseInt(parseInt(new Date(startDate).getTime()) / 1000);

    let currDate = new Date().getTime() / 1000;
    console.log("currDate", currDate, endtime, currDate < endtime);
  if (parseFloat(ticketPrice) == 0) {

      ValidateError.ticketPice = "Enter valid price";
      setValidateError(ValidateError);
      setaddButton(true)
      return false;
    }

    if (currDate > parseInt(endtime)) {
      await toast.error("End Time should be Future date",
        {
          position: position.position,
          style: style,
          iconTheme: iconTheme,
        })
      return false;
    } else {
      var data = {
        endtime: endDiff.toString(),
        ticketPice:ticketPrice,
        reward1: reward1 ,
        reward2: reward2,
        reward3: reward3,
        reward4: reward4,
        reward5: reward5,
        reward6: reward6,

      }

      var errors = await AddLotteryvalidation(data);
      if (
        parseInt(reward1) +
        parseInt(reward2) +
        parseInt(reward3) +
        parseInt(reward4) +
        parseInt(reward5) +
        parseInt(reward6) >
        100 ||
        parseInt(reward1) +
        parseInt(reward2) +
        parseInt(reward3) +
        parseInt(reward4) +
        parseInt(reward5) +
        parseInt(reward6) <
        100
      ) {
        await toast.error("Total split up must be 100...!!!",
          {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
          })
        return false;
      } else if (isEmpty(errors)) {

        var arra = [];
        arra.push(parseInt(reward1 * 100));
        arra.push(parseInt(reward2 * 100));
        arra.push(parseInt(reward3 * 100));
        arra.push(parseInt(reward4 * 100));
        arra.push(parseInt(reward5 * 100));
        arra.push(parseInt(reward6 * 100));
        let convertamout = ticketPrice * 10 ** 18;


        const result = await StartLottery(endDiff.toString(), convertamout, discount, arra, fee, accountInfo);
      } else {
        setValidateError(errors)
        // return false

      }
    }

  }


  const filterPassedTime = (time) => {
    console.log(time, "filterPassedTime");
    const currentDate = new Date();
    const selectedDate = new Date(time);
    console.log(
      currentDate.getTime() < selectedDate.getTime(),
      "filterPassedTime"
    );
    return currentDate.getTime() < selectedDate.getTime();
  };


  const chooseEndTime = (date) => {


    if (Date.parse(date) > Date.now()) {
      const endDiff = parseInt(parseInt(new Date(date).getTime()) / 1000);
      console.log("endDiff>>>>>>>1", endDiff);
      setStartDate(endDiff);
      setaddButton(false)
    }
    else {
      ValidateError.endtime = "End Time needs to be after now";
      setValidateError(ValidateError);
      setaddButton(true)
    }
  }

  return (
    <div>

      <Header />
      <div className={`${cascading.innerpages} ${cascading.predictionPage} ${cascading.lotteryPage}`}>
        <div className={`container`}>
          <div className={`row flex-column ${cascading.topSection}`}>
            <h3 className={`text-center ${cascading.pageTitle}`}>Add Lottery</h3>
            <div className="mt-4">

              <div
                className={`row justify-content-center ${cascading.swapcardrow}`}
              >
                <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 `}>
                  <div className={` ${cascading.cardstylelottery} dfdsfsdf`}>

                    <div className={`${cascading.midrow}`}>

                      <div className={`${cascading.inputsec} mb-3`}>
                        <div className={`${cascading.balancerow}`}>
                          <p className={`${cascading.inputlabel}`}>
                            Choose End Time (Maximum 5 days from the current date and time)
                          </p>

                        </div>

                        {/* <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                          <div>
                            <input
                              type="text"
                              className={`from-control`}
                              id="endtime"
                              aria-describedby="endtime"
                              placeholder="End Time in timestamp"
                              value={endtime}
                              onChange={endtimeinputChange}

                            />
                          </div>

                        </div> */}

                        <div className="custom_date_picker">
                          <DatePicker
                            minDate={new Date()}
                            showTimeSelect
                            placeholderText="Select Date and Time"



                            // onChange={(date) => chooseEndTime(date)}
                            selected={startDate}
                            onChange={(date) =>{
                              if (Date.parse(date) > Date.now()) {
                                
                                setStartDate(date)
                             
                                setaddButton(false)
                                ValidateError.endtime = " ";
                                setValidateError(ValidateError);
                              } else{
                                ValidateError.endtime = "End Time needs to be after now";
                                setValidateError(ValidateError);
                                setaddButton(true)

                              }
                            }

                             }

                            dateFormat="MMMM d, yyyy h:mm aa"
                            filterTime={(time) => filterPassedTime(time)}
                          />
                        </div>

                      </div>
                      {ValidateError.endtime && (
                        <span className="text-danger">
                          {ValidateError.endtime}
                        </span>
                      )}

                      <div className={`${cascading.inputsec} mb-3`}>
                        <div className={`${cascading.balancerow}`}>
                          <p className={`${cascading.inputlabel}`}>
                            Ticket Rate
                          </p>

                        </div>

                        <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                          <div>
                            <input
                              type="text"
                              // className={`from-control`}
                              id="ticketprize"
                              name="ticketprize"
                              aria-describedby="ticketprize"
                              placeholder="Ticket Rate"
                              onChange={inputChange}
                              value={ticketPrice}

                            // onChange={(e) => { setticketPice(e.target.value) }}

                            />
                          </div>

                        </div>

                      </div>
                      {console.log("ValidateError.ticketPrice", ValidateError.ticketPrice)}
                      {ValidateError && ValidateError.ticketPice && (
                        <span className="text-danger">
                          {ValidateError.ticketPice}
                        </span>
                      )}


                      <p className={`${cascading.themetext}`}>Prizes Rewards</p>
                      <div className={`${cascading.inputsec} mb-3`}>
                        <div className={`${cascading.balancerow}`}>
                          <p className={`${cascading.inputlabel}`}>
                            First Prize in %
                          </p>

                        </div>

                        <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                          <div>
                            <input
                              type="text"
                              className={`from-control`}
                              id="first"
                              aria-describedby="ticketprize"
                              placeholder="First Prize in %"
                              value={reward1}
                              onChange={reward1inputChange}

                            />
                          </div>
                        </div>


                      </div>
                      {ValidateError.reward1 && (
                        <span className="text-danger">
                          {ValidateError.reward1}
                        </span>
                      )}
                      <div className={`${cascading.inputsec} mb-3`}>
                        <div className={`${cascading.balancerow}`}>
                          <p className={`${cascading.inputlabel}`}>
                            Second Prize in %
                          </p>

                        </div>

                        <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                          <div>
                            <input
                              type="text"
                              className={`from-control`}
                              id="second"
                              aria-describedby="ticketprize"
                              placeholder="second Prize in %"
                              value={reward2}
                              onChange={reward2inputChange}

                            />
                          </div>
                        </div>


                      </div>
                      {ValidateError.reward2 && (
                        <span className="text-danger">
                          {ValidateError.reward2}
                        </span>
                      )}
                      <div className={`${cascading.inputsec} mb-3`}>
                        <div className={`${cascading.balancerow}`}>
                          <p className={`${cascading.inputlabel}`}>
                            Third Prize in %
                          </p>

                        </div>

                        <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                          <div>
                            <input
                              type="text"
                              className={`from-control`}
                              id="third"
                              aria-describedby="ticketprize"
                              placeholder="Third Prize in %"
                              value={reward3}
                              onChange={reward3inputChange}
                            />
                          </div>
                        </div>


                      </div>
                      {ValidateError.reward3 && (
                        <span className="text-danger">
                          {ValidateError.reward3}
                        </span>
                      )}
                      <div className={`${cascading.inputsec} mb-3`}>
                        <div className={`${cascading.balancerow}`}>
                          <p className={`${cascading.inputlabel}`}>
                            Fourth Prize in %
                          </p>

                        </div>

                        <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                          <div>
                            <input
                              type="text"
                              className={`from-control`}
                              id="fourth"
                              aria-describedby="ticketprize"
                              placeholder="Fourth Prize in %"
                              value={reward4}
                              onChange={reward4inputChange}

                            />
                          </div>
                        </div>


                      </div>
                      {ValidateError.reward4 && (
                        <span className="text-danger">
                          {ValidateError.reward4}
                        </span>
                      )}
                      <div className={`${cascading.inputsec} mb-3`}>
                        <div className={`${cascading.balancerow}`}>
                          <p className={`${cascading.inputlabel}`}>
                            Fifth Prize in %
                          </p>

                        </div>

                        <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                          <div>
                            <input
                              type="text"
                              className={`from-control`}
                              id="Fifth"
                              aria-describedby="ticketprize"
                              placeholder="Fifth Prize in %"
                              value={reward5}
                              onChange={reward5inputChange}
                            />
                          </div>
                        </div>


                      </div>
                      {ValidateError.reward5 && (
                        <span className="text-danger">
                          {ValidateError.reward5}
                        </span>
                      )}
                      <div className={`${cascading.inputsec} mb-3`}>
                        <div className={`${cascading.balancerow}`}>
                          <p className={`${cascading.inputlabel}`}>
                            Sixth Prize in %
                          </p>

                        </div>

                        <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                          <div>
                            <input
                              type="text"
                              className={`from-control`}
                              id="sixth"
                              aria-describedby="ticketprize"
                              placeholder="Sixth Prize in %"
                              value={reward6}
                              onChange={reward6inputChange}


                            />
                          </div>
                        </div>


                      </div>
                      {ValidateError.reward6 && (
                        <span className="text-danger">
                          {ValidateError.reward6}
                        </span>
                      )}

                      {/* {[...Array(count)].map(()=>
{
   return <>
                  <div className={`${cascading.inputsec} mb-3`}>
                    <div className={`${cascading.balancerow}`}>
                      <p className={`${cascading.inputlabel}`}>
                      Prize % 
                      </p>
                        
                    </div>

                    <div className={`${cascading.inputsecrow} ${cascading.inputsecrowblk}`}>
                      <div>
                        <input
                          type="text"
                          className={`from-control`}
                        //   id="firstprize"
                          aria-describedby="firstprize"
                          placeholder="Prize %"
                         
                        />
                      </div>

                      <div>
                        </div>
             
                    </div>

                   
                  </div>
                  </>
})}

                  <div className="d-flex align-items-center justify-content-end mb-4">
                  <button
                                                    className={`btn ${cascading.iconbtnnew} me-2`}
                                                    onClick={() => {
                                                       setCount(count + 1)
                                                    }}> <FaPlus /> </button>

<button
                                                    className={`btn ${cascading.iconbtnnew}`}
                                                    onClick={() => {
                                                       setCount(count - 1)
                                                    }}> <FaMinus /> </button>
                </div>  */}

{isadd ? 
<>

                      <button
                        className={`btn ${cascading.formbtn}`}
                        disabled={isadd}
                        onClick={() => onSubmit()} >
                        Add
                      </button> 

                      {note && (
                        <span className="text-danger text-center">
                          {note}
                        </span>
                      )}
                      </>
                      : 

<button
className={`btn ${cascading.formbtn}`}
disabled={addButton}
onClick={() => onSubmit()} >
Add
</button>}
                      
                   

                    </div>





                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>


      </div>

      <Footer />

    </div>
  );
};

export default Addlottery;
