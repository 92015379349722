import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/stakestyle.module.css";
import toast, { Toaster } from "react-hot-toast";

import { useDispatch } from "react-redux";

import { IoMdClose } from "react-icons/io";

import bnbimg from "../assests/images/bnb.png";
import { AiOutlineCalculator } from "react-icons/ai";
import BigNumber from "bignumber.js";
import Calculatormodal from "./calculatormodal";

//lib
import { toFixedNumber } from "../lib/FixedNumber";

//hooks
import { Deposit } from "../hooks/usePools";

import {
  getActivePools,
  fetchPoolsStakingLimits,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
} from "../hooks/usePools";
import {
  getApy,
  getInterestBreakdown,
  getPrincipalForInterest,
  getRoi,
} from "../hooks/useFarms";

export default function Stakepoolmodal(props) {
  // state
  const [stakepoolmodal, setStakepoolmodal] = useState(true);
  const [points, setPoints] = useState(0);
  const [stakingBal, setStakingBal] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [pending, setPending] = useState(false);
  const [calshow, setcalshow] = useState(false);
  const [ROIRate, setROIRate] = useState(0);

  const dispatch = useDispatch();

  const { userData, datas } = props.stake;


  const rangeOnchange = async (value) => {
    // const { name, value } = e.target;
    setPoints(value);
    let stakingbalance = (userData?.stakingTokenBalance * value) / 100;
    const usdValueStaked = new BigNumber(stakingbalance).times(
      datas.stakingTokenPrice
    );
    setStakingBal(stakingbalance / 10 ** 18);
    setUsdValue(usdValueStaked.toNumber() / 10 ** 18);
  };

  const onStakevalueChange = (e) => {
    try {
      const { value, name } = e.target;
      setStakingBal(value);
      const usdValueStaked = new BigNumber(value * 10 ** 18).times(
        datas.stakingTokenPrice
      );
      console.log("usdValueStaked", usdValueStaked, datas.stakingTokenPrice);
      setUsdValue(usdValueStaked.toNumber() / 10 ** 18);
    } catch (err) {
      console.log(err, "onStakevalueChange");
    }
  };

  const staking = async () => {
    setPending(true);
    console.log("stakingBal", stakingBal * 10 ** 18);
    let stake = await Deposit(
      toFixedNumber(parseFloat(stakingBal * 10 ** 18).toFixed(0)),
      datas?.contractAddress
    );
    console.log("stake", stake);
    if (stake == true) {
      fetchPoolsPublicDataAsync(dispatch);
      fetchPoolsUserDataAsync(dispatch);
      toast.success(
        `Your ${datas?.stakingToken?.symbol} funds have been staked in the pool!`
      );
      props.onHide();
      setPending(false);
    } else {
      toast.error("Try Again!");
    }
  };

  const ROICal = () => {
    try {
      const principalInUSDAsNumber = toFixedNumber(usdValue);
      console.log("principalInUSDAsNumber", principalInUSDAsNumber);
      const interestBreakdown = getInterestBreakdown({
        principalInUSD: principalInUSDAsNumber,
        apr: props?.apy,
        earningTokenPrice: datas?.earningTokenPrice,
      });
      const annualRoi = interestBreakdown[3] * datas?.earningTokenPrice;
      const formattedAnnualRoi = annualRoi;
      setROIRate(annualRoi);
      console.log("formattedAnnualRoi", formattedAnnualRoi);
    } catch (err) {
      console.log("err_ROI", err);
    }
  };

  useEffect(() => {
    ROICal();
  }, []);

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={stakepoolmodal}
          onHide={props.onHide}
          className={`${cascading.modalstyle}`}
          backdrop="static"
        >
          <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle}`}>Stake in Pool</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.onHide}
            />
          </Modal.Header>
          <Modal.Body
            className={`${cascading.modalclr} ${cascading.modalbody}`}
          >
            <p className="text-center text-white">
              Max stake for this pool:{" "}
              {toFixedNumber(datas?.stakingLimit / 10 ** 18)}{" "}
              {datas?.stakingToken?.symbol}
            </p>
            {/* <div className={`${cascading.inputsec} mb-4`}>

              <div className={`${cascading.balancerow}`}>
                <p className={`${cascading.inputlabel}`}>Stake :</p>
                <img src={props?.image} className={`img-fluid ${cascading.logoimg}`} alt='img' />

                <p className={`${cascading.inputlabel}`}>{datas?.stakingToken?.symbol}</p>
              </div>

            </div> */}
            <div className={`${cascading.inputsec} mb-4`}>
              <div
                className={`${cascading.balancerow} ${cascading.balancerownowrap}`}
              >
                <p className={`${cascading.inputlabel} mb-0`}>Stake</p>
                <div className="d-flex align-items-center">
                  <img
                    src={props?.image}
                    className={`img-fluid ${cascading.logoimg_coin}`}
                    alt="img"
                  />
                  <p className={`${cascading.inputlabel} mb-0 ms-2`}>
                    {datas?.stakingToken?.symbol}
                  </p>
                </div>
              </div>

              <div className={`${cascading.inputsecrowcol}`}>
                <div>
                  <input
                    type="text"
                    className={`from-control`}
                    id="tokenAval"
                    aria-describedby="emailHelp"
                    placeholder="0.0"
                    value={stakingBal}
                    onChange={(e) => {
                      onStakevalueChange(e);
                    }}
                  />
                </div>

                <div className={`${cascading.rightmaxbtn}`}>
                  {/* <button
                                                    className={`btn ${cascading.currencybtn}`}
                                                   > Max </button> */}

                  <p className={`${cascading.inputlabel} mb-0`}>
                    ~{" "}
                    {usdValue
                      ? toFixedNumber(parseFloat(usdValue).toFixed(15))
                      : 0.0}{" "}
                    USD
                  </p>
                </div>
              </div>
            </div>
            <p className={`${cascading.inputlabelwhite} text-end`}>
              Balance :{userData?.stakingTokenBalance / 10 ** 18}
            </p>

            <div className={`${cascading.modalslider}`}>
              <div className={`${cascading.rangesec} mt-0`}>
                <p className={`${cascading.inputlabeltheem}`}>{points} %</p>

                <input
                  type="range"
                  className={`form-range `}
                  id="customRange"
                  name="points"
                  min="0"
                  max="100"
                  value={points}
                  onChange={(e) => {
                    rangeOnchange(e.target.value);
                  }}
                />
                <div
                  className={`${cascading.btngrpsec} ${cascading.btngrpsecnowrap}`}
                >
                  <button
                    className={`btn ${cascading.btnstyle}`}
                    onClick={() => {
                      rangeOnchange(25);
                    }}
                  >
                    25%
                  </button>
                  <button
                    className={`btn ${cascading.btnstyle}`}
                    onClick={() => {
                      rangeOnchange(50);
                    }}
                  >
                    50%
                  </button>
                  <button
                    className={`btn ${cascading.btnstyle}`}
                    onClick={() => {
                      rangeOnchange(75);
                    }}
                  >
                    75%
                  </button>
                  <button
                    className={`btn ${cascading.btnstyle}`}
                    onClick={() => {
                      rangeOnchange(100);
                    }}
                  >
                    Max
                  </button>
                </div>
              </div>
            </div>
            <p className="text-center text-white mt-4">
              Annual ROI at current rates: ${!isNaN(ROIRate) ? ROIRate : 0}
              <button
                className={`btn ${cascading.connect_btn_disable} ms-2`}
                onClick={() => {
                  props.onshowcalculator();
                }}
              >
                <AiOutlineCalculator />
              </button>
            </p>

            <div className="d-flex align-items-center justify-content-between">
              <button
                className={`btn ${cascading.cancelbtn}`}
                type="button"
                onClick={props.onHide}
              >
                Cancel
              </button>

              <button
                className={`btn ms-3 ${cascading.connectwalletbtn}`}
                type="button"
                onClick={() => {
                  staking();
                }}
                disabled={stakingBal == 0}
              >
                {pending == true ? "Confirming..." : "Confirm"}
              </button>
            </div>
            {/* <div className='text-center mt-4'>
              <button className={`${cascading.declabel}`} target="_blank" >Get DSP-BNB LP</button>

            </div> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
