import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/confirmswapstyle.module.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import infoimg from "../assests/images/infoicon.png";

import { IoMdClose } from "react-icons/io";
import { FaDownLong } from "react-icons/fa6";


export default function ConfirmSwapmodal(props) {
  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
        backdrop="static"
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle} mb-0`}>Confirm Swap</h4>
          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.swapsec}`}>
            <div className={`${cascading.rowslist}`}>
              <p>
                <img src={infoimg} className={`${cascading.coinimg}`} />
                {props.tokenAval}
              </p>
              <p>{props.tokenA.symbol}</p>
            </div>
            <div className={`my-3 d-flex justify-content-center`}>
              <FaDownLong fill="#7645d9" />
            </div>
            <div className={`${cascading.rowslist}`}>
              <p>
                <img src={infoimg} className={`${cascading.coinimg}`} />{" "}
                {props.tokenBval}
              </p>
              <p>{props.tokenB.symbol}</p>
            </div>
          </div>
          <p className={`${cascading.desccontent}`}>
            {props.isFromEdit
              ? `Output is estimated. You will receive at least  ${props.miniRecivied} ${props.tokenB.symbol} or the transactions will revert`
              : `Input is estimated. You will sell at most ${props.maxSold} ${props.tokenA.symbol} or the transactions will revert`}
          </p>

          <div className={`${cascading.listcard}`}>
            <ul>
              <li>
                <p>Price</p>
                <p className={`${cascading.valueLabel}`}>{props.price}</p>
              </li>
              <li>
                <p>{props.isFromEdit ? "Minimum received" : "Maximum sold"}</p>
                <p className={`${cascading.valueLabel}`}>
                  {props.isFromEdit
                    ? `${props.miniRecivied} ${props.tokenB.symbol}`
                    : `${props.maxSold} ${props.tokenA.symbol}`}
                </p>
              </li>
              <li>
                <p>Price Impact</p>
                <p className={`${cascading.valueLabel}`}>
                  {props.priceImpact} %
                </p>
              </li>

              <li>
                <p>Liquidity Provider Fee</p>
                <p className={`${cascading.valueLabel}`}>
                  {props.lpFee} {props.tokenA.symbol}
                </p>
              </li>
            </ul>
          </div>

          <button
            className={`btn ${cascading.connectwalletbtn}`}
            type="button"
            onClick={() => {
              props.onConfirm();
              props.onHide();
            }}
          >
            Confirm Swap
          </button>
          {/* <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}
