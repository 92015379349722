import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/confirmremovestyle.module.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import infoimg from "../assests/images/infoicon.png";

import fileObjectUrl from "../lib/img";
import { toFixedNumber } from "../lib/FixedNumber";

import { IoMdClose } from "react-icons/io";
import { FaDownLong } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";

export default function ConfirmRemovemodal(props) {
  var propsdata = props?.supplyObj;
  console.log("propsdata", propsdata);

  useEffect(() => {
    console.log("props", props?.supplyObj, props?.pairInfo);
  }, []);

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
        backdrop="static"
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle}`}>You will receive</h4>
          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.swapsec}`}>
            <div className={`${cascading.rowslist}`}>
              <p>
                {/* 0.0001567890765678 */}
                {toFixedNumber(propsdata?.out1)}
              </p>
              <p>
                <img
                  src={fileObjectUrl(propsdata?.fromToken?.image)}
                  className={`me-2 ${cascading.coinimg}`}
                />
                {propsdata?.fromToken?.symbol}
              </p>
            </div>
            <div
              className={`my-3 d-flex align-items-center justify-content-center`}
            >
              <FaPlus fill="#7645d9" />
            </div>
            <div className={`${cascading.rowslist}`}>
              <p>
                {/* 0.0001567890765678 */}

                {toFixedNumber(propsdata?.out2)}
              </p>
              <p>
                <img
                  src={fileObjectUrl(propsdata?.toToken?.image)}
                  className={`me-2 ${cascading.coinimg}`}
                />
                {propsdata?.toToken?.symbol}
              </p>
            </div>
          </div>
          <p className={`${cascading.desccontent}`}>
            Output is estimated. If the price changes by more than 0.5% your
            transaction will revert.
          </p>

          <div className={`${cascading.listcard}`}>
            <ul>
              <li>
                <p>
                  {`${propsdata?.fromToken?.symbol}/${propsdata?.toToken?.symbol}`}{" "}
                  Burned
                </p>
                <div>
                  <img
                    src={fileObjectUrl(propsdata?.fromToken?.image)}
                    className={`${cascading.coinimg} ${cascading.coinimgstyle}`}
                  />
                  <img
                    src={fileObjectUrl(propsdata?.toToken?.image)}
                    className={`ms-1 ${cascading.coinimg} ${cascading.coinimgstyle}`}
                  />
                  <span
                    className={`${cascading.coinvaluespan} text-white ms-1`}
                  >
                    {/* 0.00015676786050 */}
                    {propsdata?.lpValue}
                  </span>
                </div>
              </li>
              {/* <li>
        <p>MInimum received</p>
        <p>0.000 WBNB</p>
      </li> */}
              <li>
                <p>Price</p>
                <div>
                  <p className={`${cascading.labelvalue}`}>
                    1{propsdata?.fromToken?.symbol} = {propsdata?.toTokenPer}{" "}
                    {propsdata?.toToken?.symbol}
                  </p>
                  <p className={`${cascading.labelvalue}`}>
                    1 {propsdata?.toToken?.symbol} = {propsdata?.fromTokenPer}{" "}
                    {propsdata?.fromToken?.symbol}
                  </p>
                </div>
              </li>

              {/* <li>
        <p>Liquidity Provider Fee</p>
        <p>0.000 YUM</p>
      </li> */}
            </ul>
          </div>

          <button
            className={`btn ${cascading.connectwalletbtn}`}
            type="button"
            onClick={() => {
              props?.supplyData();
            }}
          >
            Confirm
          </button>
          {/* <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}
