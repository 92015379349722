import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/waitingremovestyle.module.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
import Switch from "react-switch";
import coinimg from "../assests/images/bnb.png";

import metaicon from "../assests/images/metamask.png";
import walleticon from "../assests/images/walletconnect.png";

import { toFixedNumber } from "../lib/FixedNumber";

import { IoMdClose } from "react-icons/io";
export default function WaitingRemovemodal(props) {
  var propsdata = props?.supplyObj;
  console.log("propsdata", propsdata);

  useEffect(() => {
    console.log("WaitingSupplymodal", props?.supplyObj);
  }, []);

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className={`${cascading.modalstyle}`}
        backdrop="static"
      >
        <Modal.Header
          className={`${cascading.modalclr} ${cascading.modalheader}`}
        >
          <h4 className={`${cascading.modaltitle} mb-0`}>You will receive</h4>
          <IoMdClose
            className={`${cascading.closebtn}`}
            onClick={props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <p className={`${cascading.headlabel}`}>Waiting For Confirmation</p>
          <p className={`${cascading.vallabel}`}>
            Removing{" "}
            {`${toFixedNumber(propsdata?.out1)}${propsdata?.fromToken?.symbol
              } and ${toFixedNumber(propsdata?.out2)}${propsdata?.toToken?.symbol
              }`}
          </p>
          <p className={`${cascading.declabel}`}>
            Confirm this transcation in your wallet
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
}
