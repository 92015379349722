import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdHistory } from "react-icons/md";
import cascading from "../../assests/css/liquidity.module.css";
import iconimg1 from "../../assests/images/coinicon.png";

import Footer from "../../common/footer";
import { FaArrowDown } from "react-icons/fa";
import Header from "../../common/header";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaAngleDown, FaPlus, FaArrowLeft } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import bnbimg from "../../assests/images/bnb.png";
import Walletmodal from "../../modals/walletmodal";
import { useSelector } from "react-redux";
import Recenthistory from "../../modals/recenthistory";
import Selecttoken from "../../modals/selecttoken";
import { Tooltip } from "react-bootstrap";
import Settingsmodal from "../../modals/settingsmodal";
import ConfirmRemovemodal from "../../modals/confirmRemovemodal";
import CompletedRemovemodal from "../../modals/completedRemovemodal";
import WaitingRemovemodal from "../../modals/waitingRemovemodal";

import coinimg from "../../assests/images/infoicon.png";
import { ZEROTH_ADDRESS } from "../../config/env";
import BigNumber from 'bignumber.js';


//lib
import { isEmpty } from "../../lib/isEmpty";
import fileObjectUrl from "../../lib/img";
import { toFixedNumber } from "../../lib/FixedNumber";
//hooks
import { GetPairInfo } from "../../hooks/useFactory";
import { GetAmountsOut, GetAmountsIn } from "../../hooks/useRouter";
import { ApproveToken, checkIsApproved } from "../../hooks/useErc20";
import {
  Removeliquidity,
  RemoveLiquidityETH,
  getRouterAddress,
} from "../../hooks/useRouter";
import { getTokens,GetCurrencyBalance, multicall } from "../../hooks/useTokens";
import { getWalletAddress } from "../../lib/localStorage";
import { IsCurrency, IsWrappedCurrency,roundToSignificant } from '../../hooks/useCommon';
import pair_ABI from '../../config/abi/pairAbi.json'
import ERC20_ABI from "../../config/abi/ERC20.json";

export default function RemoveLiquidity() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);
  const [swapsec, setSwapsec] = useState(true);
  const [walletmodalshow, setWalletmodalshow] = useState(false);
  const [recentshow, setRecentshow] = useState(false);
  const [tokenshow, setTokenshow] = useState(false);
  const [tokenshow2, setTokenshow2] = useState(false);
  const [settingshow, setSettingshow] = useState(false);
  const [commontoken, setCommontoken] = useState(false);
  const [removetab, setRemovetab] = useState(false);
  const [fromToken, setFromToken] = useState("");
  const [toToken, setToToken] = useState("");
  const [token, setToken] = useState("");
  const [lpValue, setLpValue] = useState("");
  const [out1, setOut1] = useState("");
  const [out2, setOut2] = useState("");
  const [pairInfo, setPairInfo] = useState("");
  const [lpApprove, setLpApprove] = useState(false);
  const [points, setPoints] = useState(0);
  const [changeBNB, setChangeBNB] = useState("");
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [toTokenPer, setToTokenPer] = useState("");
  const [fromTokenPer, setFromTokenPer] = useState("");
  const [succesSupply, setSuccesSupply] = useState(false);
  const [waitingRemove, setWaitingRemove] = useState(false);

  const userdetail = useSelector((state) => state.admin);
  const { slippage, deadline } = useSelector((state) => state.settings);
  console.log("slippage", slippage);

  // useEffect(() => {
  //   if (state) {
  //     console.log("statte", state.token);
  //     if (!isEmpty(state?.token?.token1)) {
  //       setFromToken(state?.token?.token1);
  //       if (state?.token?.token1.symbol == "WBNB") {
  //         setChangeBNB("BNB");
  //       } else if (state?.token?.token1.symbol == "BNB") {
  //         setChangeBNB("WBNB");
  //       }
  //     }
  //     if (!isEmpty(state?.token?.token2)) {
  //       setToToken(state?.token?.token2);
  //       if (state?.token?.token2.symbol == "WBNB") {
  //         setChangeBNB("BNB");
  //       } else if (state?.token?.token2.symbol == "BNB") {
  //         setChangeBNB("WBNB");
  //       }
  //     }
  //     if (!isEmpty(state?.token)) {
  //       setToken(state?.token);
  //       let amount0 =
  //         (state.token?.lpBalance * state.token?.reserveA) /
  //         state.token?.totalSupply;
  //       let amount1 =
  //         (state.token?.lpBalance * state.token?.reserveB) /
  //         state.token?.totalSupply;
  //       console.log("amount0_amount0", state?.token, amount0, amount1);
  //       setFromTokenPer(amount0 / amount1);
  //       setToTokenPer(amount1 / amount0);
  //     }
  //   }
  // }, [state]);

  useEffect(() => {
    if (state) {
        console.log("statte", state.token)
        if (!isEmpty(state?.token?.token1)) {
            setFromToken(state?.token?.token1)
            // if (state?.token?.token1.symbol == 'WBNB') {
            if (IsWrappedCurrency(state?.token?.token1)) {
                let Currency = state?.token?.token1.symbol.split("W")[1]
                console.log(Currency, 'Currency')
                // setChangeBNB('BNB')
                setChangeBNB(Currency)
            } else if (IsCurrency(state?.token?.token1)) { /// state?.token?.token1.symbol == 'BNB'
                let Currency = 'W' + state?.token?.token1.symbol
                // setChangeBNB('WBNB')
                setChangeBNB(Currency)
            }
        }
        if (!isEmpty(state?.token?.token2)) {
            setToToken(state?.token?.token2)
            if (IsWrappedCurrency(state?.token?.token2)) {//state?.token?.token2.symbol == 'WBNB'
                let Currency = state?.token?.token1.symbol.split("W")[1]
                // setChangeBNB('BNB')
                setChangeBNB(Currency)
            } else if (IsCurrency(state?.token?.token2)) {//state?.token?.token2.symbol == 'BNB'
                let Currency = 'W' + state?.token?.token1.symbol
                // setChangeBNB('WBNB')
                setChangeBNB(Currency)
            }
        }
        if (!isEmpty(state?.token)) {
            setToken(state?.token)
            let amount0 = state.token?.lpBalance * (state.token?.reserveA) / state.token?.totalSupply;
            let amount1 = state.token?.lpBalance * (state.token?.reserveB) / state.token?.totalSupply;
            console.log("amount0_amount0", state?.token, amount0, amount1)
            setFromTokenPer(amount0 / amount1)
            setToTokenPer(amount1 / amount0)
            Approve(state.token.lpaddress,state.token.lpBalance)
        }
    }
}, [state])

useEffect(() => {
  if (token?.lpBalance == 0) {
      setOut1("")
      setOut2("")
  }
}, [out1, out2])


  const onFromTokenChange = async (value) => {
    if (value.address == toToken?.address) {
      setToToken(fromToken);
    }
    setFromToken(value);
  };

  const onToTokenChange = async (value) => {
    console.log(value, "value");
    if (value.address == fromToken?.address) {
      setFromToken(toToken);
    }
    setToToken(value);
  };
  console.log(toToken, "toToken");

  // const lpMax = () => {
  //   setLpValue(token?.lpBalance);
  //   let amount0 = (token?.lpBalance * token?.reserveA) / token?.totalSupply;
  //   let amount1 = (token?.lpBalance * token?.reserveB) / token?.totalSupply;
  //   console.log("amount1", amount0, amount1);
  //   setOut1(amount0);
  //   setOut2(amount1);
  // };

  const lpMax = () => {
    setLpValue(token?.lpBalance)
    let lpbalance = token?.lpBalance / token?.lpBalance * 100
    console.log("lpbalance",lpbalance,token?.lpBalance)
    lpbalance = isNaN(lpbalance) ? 0 : lpbalance
    setPoints(lpbalance)
    let amount0 = token?.lpBalance * (token?.reserveA) / token?.totalSupply;
    let amount1 = token?.lpBalance * (token?.reserveB) / token?.totalSupply;
    console.log("amount1", amount0, amount1)
    setOut1(amount0)
    setOut2(amount1)
}

  // const GetAmounts = async (out1) => {
  //   let addressArr = [token?.token1?.address, token?.token2?.address];
  //   let amountsIn = toFixedNumber(out1 * 10 ** 18);
  //   var amount = await GetAmountsOut(addressArr, amountsIn);
  //   setOut2(amount?.amountOut);
  //   console.log(
  //     "amount",
  //     amount,
  //     amount?.amountOut,
  //     toFixedNumber(
  //       Math.min(
  //         (out1 * token?.totalSupply) / token?.reserveA,
  //         (amount?.amountOut * token?.totalSupply) / token?.reserveB
  //       )
  //     )
  //   );
  //   setLpValue(
  //     toFixedNumber(
  //       Math.min(
  //         (out1 * token?.totalSupply) / token?.reserveA,
  //         (amount?.amountOut * token?.totalSupply) / token?.reserveB
  //       )
  //     )
  //   );
  // };

  const GetAmounts = async (out1) => {
    let addressArr = [
        token?.token1?.address,
        token?.token2?.address
    ]
    let amountsIn = toFixedNumber(out1 * 10 ** 18)
    var amount = await GetAmountsOut(addressArr, amountsIn)
    setOut2(amount?.amountOut)
    console.log("amount", amount, amount?.amountOut, toFixedNumber(Math.min(out1 * token?.totalSupply / token?.reserveA, amount?.amountOut * token?.totalSupply / token?.reserveB)))
    let lpValue = toFixedNumber(Math.min(out1 * token?.totalSupply / token?.reserveA, amount?.amountOut * token?.totalSupply / token?.reserveB))
    if(lpValue > token.lpBalance){
        setLpValue(lpValue)
    }else{
        setLpValue(lpValue)
        let point =  (lpValue / token.lpBalance)*100
        console.log("GetAmounts_point",point)
        setPoints(point)
    }
    

}


  // const GetAmountsin = async (out2) => {
  //   let addressArr = [token?.token1?.address, token?.token2?.address];
  //   let amountsIn = toFixedNumber(out2 * 10 ** 18);
  //   var amount = await GetAmountsIn(addressArr, amountsIn);
  //   setOut1(amount?.amountIn);
  //   console.log(
  //     "amount",
  //     amount,
  //     amount?.amountIn,
  //     toFixedNumber(
  //       Math.min(
  //         (amount?.amountIn * token?.totalSupply) / token?.reserveA,
  //         (amount?.amountOut * token?.totalSupply) / token?.reserveB
  //       )
  //     )
  //   );
  //   setLpValue(
  //     toFixedNumber(
  //       Math.min(
  //         (amount?.amountIn * token?.totalSupply) / token?.reserveA,
  //         (out2 * token?.totalSupply) / token?.reserveB
  //       )
  //     )
  //   );
  // };

  const GetAmountsin = async (out2) => {
    let addressArr = [
        token?.token1?.address,
        token?.token2?.address
    ]
    let amountsIn = toFixedNumber(out2 * 10 ** 18)
    var amount = await GetAmountsIn(addressArr, amountsIn)
    setOut1(amount?.amountIn)
    console.log("amount", amount, amount?.amountIn, toFixedNumber(Math.min(amount?.amountIn * token?.totalSupply / token?.reserveA, amount?.amountOut * token?.totalSupply / token?.reserveB)))
    let lpValue = toFixedNumber(Math.min(amount?.amountIn * token?.totalSupply / token?.reserveA, out2 * token?.totalSupply / token?.reserveB))
    if(lpValue > token.lpBalance){
        setLpValue(lpValue)
    }else{
        setLpValue(lpValue)
        let point =  (lpValue / token.lpBalance)*100
        console.log("GetAmountsin_point",point)
        setPoints(point)
    }
    // setLpValue(toFixedNumber(Math.min(amount?.amountIn * token?.totalSupply / token?.reserveA, out2 * token?.totalSupply / token?.reserveB)))
}

  // useEffect(() => {

  // }, [out1])

  // const onChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name == "lpValue") {
  //     var numbers = /^\d+((.)|(.\d{0,30})?)$/;
  //     if (!numbers.test(e.target.value) && e.target.value !== "") {
  //       return false;
  //     }
  //     let amount0 = (value * token?.reserveA) / token?.totalSupply;
  //     let amount1 = (value * token?.reserveB) / token?.totalSupply;
  //     setLpValue(value);
  //     setOut1(toFixedNumber(amount0));
  //     setOut2(toFixedNumber(amount1));
  //   }
  //   if (name == "out1") {
  //     var numbers = /^\d+((.)|(.\d{0,30})?)$/;
  //     if (!numbers.test(e.target.value) && e.target.value !== "") {
  //       return false;
  //     }
  //     GetAmounts(value);
  //     setOut1(value);
  //   }
  //   if (name == "out2") {
  //     var numbers = /^\d+((.)|(.\d{0,30})?)$/;
  //     if (!numbers.test(e.target.value) && e.target.value !== "") {
  //       return false;
  //     }
  //     GetAmountsin(value);
  //     setOut2(value);
  //   }
  // };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "lpValue") {
        var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        if (!numbers.test(e.target.value) && e.target.value !== "") {
            return false
        }
        let amount0 = value * (token?.reserveA) / token?.totalSupply;
        let amount1 = value * (token?.reserveB) / token?.totalSupply;
        setLpValue(value)
        if(value > token?.lpBalance){
            return false
        }else{
            let lpbalance = (value / token?.lpBalance) * 100
            console.log(lpbalance,'lpbalance2')
            setPoints(lpbalance)
            setOut1(toFixedNumber(amount0))
            setOut2(toFixedNumber(amount1))
        }
        

    } 
    if (name == "out1") {
        var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        if (!numbers.test(e.target.value) && e.target.value !== "") {
            return false
        }
        GetAmounts(value)
        setOut1(value)
    }
    if (name == "out2") {
        var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
        if (!numbers.test(e.target.value) && e.target.value !== "") {
            return false
        }
        GetAmountsin(value)
        setOut2(value)
    }
};
  useEffect(() => {
    getpair();
  }, [fromToken, toToken]);

  // const getpair = async () => {
  //   var get = await GetPairInfo(fromToken?.address, toToken?.address);
  //   console.log("get", get);
  //   setPairInfo(get);
  // };

  const getpair = async () => {
    if (!isEmpty(fromToken) && !isEmpty(toToken)) {
        var get = await GetPairInfo(fromToken?.address, toToken?.address)
        console.log("get", get)
        setPairInfo(get)
        if (get !== ZEROTH_ADDRESS) {
            let { reserveA, reserveB, totalSupply, lpBalance, tokenA, tokenB, tokenAbal, tokenBbal } = await getcall(get)
            let token1 = getTokens().find((val) => (val?.address?.toLowerCase() == tokenA?.toLowerCase()));
            console.log("token1", token1, totalSupply)
            let token2 = getTokens().find((val) => (val?.address?.toLowerCase() == tokenB?.toLowerCase()));
            let currencyBal
            // console.log("currencyBal",token1?.symbol == 'BNB' || token2?.symbol == 'BNB')
            // if(token1?.symbol == 'BNB' || token2?.symbol == 'BNB' ){
            if (IsCurrency(token1) || IsCurrency(token2)) {
                currencyBal = await GetCurrencyBalance()
                // console.log("currencyBal",currencyBal,token1.symbol == 'BNB' ? { ...token1, 'balance': currencyBal }: { ...token1, 'balance': tokenAbal })
            }
            let obj = {
                reserveA: reserveA,
                reserveB: reserveB,
                token1: IsCurrency(token1) ? { ...token1, 'balance': currencyBal } : { ...token1, 'balance': tokenAbal },
                token2: IsCurrency(token2) ? { ...token2, 'balance': currencyBal } : { ...token2, 'balance': tokenBbal },
                lpBalance: lpBalance,
                totalSupply: totalSupply,
                lpaddress: get,
                // userlpBalance:userlpBalance,
                poolShare: (lpBalance * 100 / totalSupply).toFixed(2)
            }
            setFromTokenPer(reserveA / reserveB)
            setToTokenPer(reserveB / reserveA)
            setToken(obj)
           
            console.log("call_address", reserveA, reserveB, totalSupply, lpBalance, tokenA, tokenB, tokenAbal, tokenBbal)
        }
        setOut1("")
        setOut2("")
        setLpValue("")
        setPoints(0)
    }


}

const getcall = async (address) => {
  let calls = [{
      address: address,
      name: 'getReserves'
  }, {
      address: address,
      name: 'token0'
  }, {
      address: address,
      name: 'token1'
  },
  {
      address: address,
      name: 'totalSupply'
  }
  ]
  if (!isEmpty(getWalletAddress())) {
      calls.push({
          address: address,
          name: 'balanceOf',
          params: [getWalletAddress()]
      })
  }
  console.log("calls_callscalls", calls)
  var pair = await multicall(pair_ABI, calls)
  console.log("pair", pair)
  let call = [{
      address: pair[1][0],
      name: 'balanceOf',
      params: [getWalletAddress()]
  }, {
      address: pair[2][0],
      name: 'balanceOf',
      params: [getWalletAddress()]
  }]
  var tokenbalance = await multicall(ERC20_ABI, call)
  console.log("tokenbalance", tokenbalance)
  // var bal = await Balance(address)
  // console.log("bal", bal)

  return {
      reserveA: new BigNumber(pair[0][0]._hex).toNumber() / 10 ** 18,
      reserveB: new BigNumber(pair[0][1]._hex).toNumber() / 10 ** 18,
      tokenA: pair[1][0],
      tokenB: pair[2][0],
      totalSupply: new BigNumber(pair[3][0]._hex).toNumber() / 10 ** 18,
      // userlpBalance: new BigNumber(pair[4]._hex).toNumber() / 10 ** 18,
      lpBalance: isEmpty(getWalletAddress()) ? 0 : new BigNumber(pair[4][0]._hex).toNumber() / 10 ** 18,
      tokenAbal: isEmpty(getWalletAddress()) ? 0 : new BigNumber(tokenbalance[0]).toNumber() / 10 ** 18,
      tokenBbal: isEmpty(getWalletAddress()) ? 0 : new BigNumber(tokenbalance[1]).toNumber() / 10 ** 18,

  }
}

  const Approve = async (address, value) => {
    var result = await checkIsApproved(address, value);
    setLpApprove(result);
    console.log("setLpApprove", result);
  };
  useEffect(() => {
    console.log('Approve', token)
    if (!isEmpty(token)) {
        console.log('Approve1', token)
        Approve(token?.lpaddress, token?.lpBalance)
    }

}, [token,token?.lpaddress, token?.lpBalance])
  // useEffect(() => {
  //   Approve(token?.lpaddress, token?.lpBalance);
  // }, [token?.lpaddress, token?.lpBalance]);

  const initalStateSet = () => {
    try {
        // setFromToken('')
        // setToToken('')
        setOut2('')
        setOut1('')
        // setLpValue(0)
        setFromTokenPer('')
        setToTokenPer('')
        setPoints(0)
        setToken('')


    } catch (err) {
        console.log('initalStateSet__err', err, true)
    }
}

  // const supplyData = async () => {
  //   setConfirmRemove(false);
  //   setWaitingRemove(true);
  //   if (token?.token1?.name == "BNB" || token?.token2?.name == "BNB") {
  //     let tokens =
  //       token?.token1?.name == "BNB"
  //         ? token?.token2?.address
  //         : token?.token1?.address;

  //     console.log("slippage", slippage);
  //     let slippageval = parseFloat(slippage) / 100;
  //     console.log("slippageval", slippageval);

  //     let amountTokenDesired =
  //       token?.token1?.name == "BNB"
  //         ? toFixedNumber(out2)
  //         : toFixedNumber(out1);
  //     var amountTokenMin = parseFloat(amountTokenDesired) * (1 - slippageval);
  //     amountTokenMin =
  //       token?.token1?.name == "BNB"
  //         ? amountTokenMin * 10 ** token?.token2?.decimal
  //         : amountTokenMin * 10 ** token?.token1?.decimal;
  //     amountTokenMin = toFixedNumber(parseFloat(amountTokenMin).toFixed(0));
  //     console.log("amountTokenMin", amountTokenMin);

  //     let amountETHDesired =
  //       token?.token1?.name == "BNB"
  //         ? toFixedNumber(out1)
  //         : toFixedNumber(out2);
  //     var amountETHMin = parseFloat(amountETHDesired) * (1 - slippageval);
  //     amountETHMin = amountETHMin * 10 ** 18;
  //     amountETHMin = toFixedNumber(parseFloat(amountETHMin).toFixed(0));
  //     console.log("amountETHMin", amountETHMin);

  //     let deadLine = (
  //       new Date().getTime() / 1000 +
  //       parseFloat(deadline)
  //     ).toFixed(0);
  //     let liquidity = toFixedNumber(lpValue * 10 ** 18).toFixed(0);
  //     let qureyString = `Remove ${out1} ${token.token1.symbol} and ${out2} ${token?.token2?.symbol}`;
  //     let result = await RemoveLiquidityETH(
  //       tokens,
  //       liquidity,
  //       amountTokenMin,
  //       amountETHMin,
  //       deadLine,
  //       qureyString
  //     );
  //     setWaitingRemove(false);
  //     if (result) {
  //       setSuccesSupply(true);
  //     }
  //     console.log("AddLiquidityETH", result);
  //   } else {
  //     let deadLine = (
  //       new Date().getTime() / 1000 +
  //       parseFloat(deadline)
  //     ).toFixed(0);
  //     let liquidity = toFixedNumber(lpValue * 10 ** 18).toFixed(0);
  //     let amountA = toFixedNumber(out1);
  //     let amountB = toFixedNumber(out2);

  //     let slippageval = parseFloat(slippage) / 100;
  //     console.log(amountA, amountB, slippageval, "slippage_slip");
  //     var TokenAMin = parseFloat(amountA) * (1 - slippageval);
  //     TokenAMin = TokenAMin * 10 ** token?.token1?.decimal;
  //     TokenAMin = toFixedNumber(parseFloat(TokenAMin).toFixed(0));
  //     console.log("TokenAMin", TokenAMin);

  //     var TokenBMin = parseFloat(amountB) * (1 - slippageval);
  //     TokenBMin = TokenBMin * 10 ** token?.token2?.decimal;
  //     TokenBMin = toFixedNumber(parseFloat(TokenBMin).toFixed(0));
  //     console.log("TokenBMin", TokenBMin);

  //     let qureyString = `Remove ${out1} ${token.token1.symbol} and ${out2} ${token?.token2?.symbol}`;
  //     let result = await Removeliquidity(
  //       token?.token1?.address,
  //       token?.token2?.address,
  //       liquidity,
  //       TokenAMin,
  //       TokenBMin,
  //       deadLine,
  //       qureyString
  //     );
  //     setWaitingRemove(false);
  //     if (result) {
  //       setSuccesSupply(true);
  //     }
  //     console.log("RemoveLiquidity", result);
  //   }
  // };


  const supplyData = async () => {
    setConfirmRemove(false)
    setWaitingRemove(true)
    let istoken1BNB = IsCurrency(token?.token1)
    let istoken2BNB = IsCurrency(token?.token2)
    console.log('supplyData', token?.token1, token?.token2)
    // if (token?.token1?.name == "BNB" || token?.token2?.name == "BNB") {
    if (IsCurrency(fromToken) || IsCurrency(toToken)) {
        // let tokens = token?.token1?.name == 'BNB' ? token?.token2?.address : token?.token1?.address

        let tokens = istoken1BNB ? token?.token2?.address : token?.token1?.address

        console.log("slippage", slippage)
        let slippageval = parseFloat(slippage) / 100
        console.log("slippageval", slippageval)

        // let amountTokenDesired = token?.token1?.name == 'BNB' ? toFixedNumber(out2): toFixedNumber(out1)
        let amountTokenDesired = istoken1BNB ? toFixedNumber(out2) : toFixedNumber(out1)
        var amountTokenMin = parseFloat(amountTokenDesired) * (1 - slippageval);
        amountTokenMin = istoken1BNB ? amountTokenMin * 10 ** token?.token2?.decimal : amountTokenMin * 10 ** token?.token1?.decimal
        amountTokenMin = toFixedNumber(parseFloat(amountTokenMin).toFixed(0))
        console.log("amountTokenMin", amountTokenMin)

        // let amountETHDesired = token?.token1?.name == 'BNB' ? toFixedNumber(out1) : toFixedNumber(out2)
        let amountETHDesired = istoken1BNB ? toFixedNumber(out1) : toFixedNumber(out2)
        var amountETHMin = parseFloat(amountETHDesired) * (1 - slippageval);
        amountETHMin = amountETHMin * 10 ** 18
        amountETHMin = toFixedNumber(parseFloat(amountETHMin).toFixed(0))
        console.log("amountETHMin", amountETHMin)

        let deadLine = ((new Date().getTime() / 1000) + parseFloat(deadline * 60)).toFixed(0)
        let liquidity = toFixedNumber(lpValue * 10 ** 18).toFixed(0)
        let qureyString = `Remove ${toFixedNumber(out1)} ${token.token1.symbol} and ${toFixedNumber(out2)} ${token?.token2?.symbol}`
        let result = await RemoveLiquidityETH(tokens, liquidity, amountTokenMin, amountETHMin, deadLine, qureyString)
        setWaitingRemove(false)
        if (result) {
            initalStateSet()
            setSuccesSupply(true)
        }
        console.log("AddLiquidityETH", result)
    }
    else {
        let deadLine = ((new Date().getTime() / 1000) + parseFloat(deadline * 60)).toFixed(0)
        let liquidity = toFixedNumber(lpValue * 10 ** 18).toFixed(0)
        let amountA = toFixedNumber(out1)
        let amountB = toFixedNumber(out2)

        let slippageval = parseFloat(slippage) / 100
        console.log(amountA, amountB, slippageval, "slippage_slip")
        var TokenAMin = parseFloat(amountA) * (1 - slippageval);
        TokenAMin = TokenAMin * 10 ** token?.token1?.decimal
        TokenAMin = toFixedNumber(parseFloat(TokenAMin).toFixed(0))
        console.log("TokenAMin", TokenAMin)

        var TokenBMin = parseFloat(amountB) * (1 - slippageval);
        TokenBMin = TokenBMin * 10 ** token?.token2?.decimal
        TokenBMin = toFixedNumber(parseFloat(TokenBMin).toFixed(0))
        console.log("TokenBMin", TokenBMin)

        let qureyString = `Remove ${toFixedNumber(out1)} ${token.token1.symbol} and ${toFixedNumber(out2)} ${token?.token2?.symbol}`
        let result = await Removeliquidity(token?.token1?.address, token?.token2?.address, liquidity, TokenAMin, TokenBMin, deadLine, qureyString)
        setWaitingRemove(false)
        if (result) {
            initalStateSet()
            setSuccesSupply(true)
        }
        console.log("RemoveLiquidity", result)
    }
}


  const rangeOnchange = async (value) => {
    // const { name, value } = e.target;
    setPoints(value);
    let lpbalance = (token?.lpBalance * value) / 100;
    setLpValue(lpbalance);
    let amount0 = (lpbalance * token?.reserveA) / token?.totalSupply;
    let amount1 = (lpbalance * token?.reserveB) / token?.totalSupply;
    console.log("amount1", amount0, amount1, token?.reserveA, token?.reserveB);
    setOut1(amount0);
    setOut2(amount1);
  };

  // const changeToken = async () => {
  //   if (fromToken?.symbol == "BNB" || toToken?.symbol == "BNB") {
  //     let Totoken =
  //       fromToken?.symbol == "BNB"
  //         ? true
  //         : toToken?.symbol == "BNB"
  //         ? false
  //         : undefined;
  //     setChangeBNB("BNB");
  //     if (Totoken == false) {
  //       let tokens = getTokens().find((val) => val.symbol == "WBNB");
  //       console.log("setToToken", tokens);
  //       setToToken(tokens);
  //     } else if (Totoken == true) {
  //       let tokens = getTokens().find((val) => val.symbol == "WBNB");
  //       console.log("setFromToken", tokens);
  //       setFromToken(tokens);
  //     }
  //   } else if (fromToken?.symbol == "WBNB" || toToken?.symbol == "WBNB") {
  //     let Totoken =
  //       fromToken?.symbol == "WBNB"
  //         ? true
  //         : toToken?.symbol == "WBNB"
  //         ? false
  //         : undefined;
  //     setChangeBNB("WBNB");
  //     if (Totoken == false) {
  //       let tokens = getTokens().find((val) => val.symbol == "BNB");
  //       console.log("setToToken", tokens);
  //       setToToken(tokens);
  //     } else if (Totoken == true) {
  //       let tokens = getTokens().find((val) => val.symbol == "BNB");
  //       console.log("setFromToken", tokens);
  //       setFromToken(tokens);
  //     }
  //   }
  // };


  const changeToken = async () => {
    // if (fromToken?.symbol == "BNB" || toToken?.symbol == "BNB") {
    if (IsCurrency(fromToken) || IsCurrency(toToken)) {
        // let Totoken = fromToken?.symbol == "BNB" ? true : toToken?.symbol == "BNB" ? false : undefined
        let Totoken = IsCurrency(fromToken) ? true : IsCurrency(toToken) ? false : undefined
        let Currency = getTokens().find((val) => (IsCurrency(val))).symbol
        setChangeBNB(Currency)
        // setChangeBNB("BNB")
        if (Totoken == false) {
            // let tokens = getTokens().find((val) => (val.symbol == "WBNB"))
            let tokens = getTokens().find((val) => (IsWrappedCurrency(val)))
            console.log("setToToken", tokens)
            setToToken(tokens)
        }
        else if (Totoken == true) {
            // let tokens = getTokens().find((val) => (val.symbol == "WBNB"))
            let tokens = getTokens().find((val) => (IsWrappedCurrency(val)))
            console.log("setFromToken", tokens)
            setFromToken(tokens)
        }
    }
    else if (IsWrappedCurrency(fromToken) || IsWrappedCurrency(toToken)) { //fromToken?.symbol == "WBNB" || toToken?.symbol == "WBNB"
        // let Totoken = fromToken?.symbol == "WBNB" ? true : toToken?.symbol == "WBNB" ? false : undefined
        let Totoken = IsWrappedCurrency(fromToken) ? true : IsWrappedCurrency(toToken) ? false : undefined
        let Wcurrency = getTokens().find((val) => (IsWrappedCurrency(val))).symbol
        // setChangeBNB('WBNB')
        setChangeBNB(Wcurrency)
        if (Totoken == false) {
            let tokens = getTokens().find((val) => (IsCurrency(val))) //val.symbol == "BNB"
            console.log("setToToken", tokens)
            setToToken(tokens)
        }
        else if (Totoken == true) {
            let tokens = getTokens().find((val) => (IsCurrency(val))) //val.symbol == "BNB"
            console.log("setFromToken", tokens)
            setFromToken(tokens)
        }
    }
}
  var obj = {
    fromToken: fromToken,
    toToken: toToken,
    out1: out1,
    out2: out2,
    lpValue: lpValue,
    fromTokenPer: fromTokenPer,
    toTokenPer: toTokenPer,
    // toTokenper: toTokenper,
    // fromTokenper: fromTokenper,
    // lpBalance: lpBalance,
    // poolShare:(poolShare).toFixed(2)
  };

  return (
    <>
      <Header />
      <div
        className={`${cascading.innerpages} ${cascading.liquiditypage}  ${cascading.removepage}`}
      >
        <div className={`container`}>
          <div
            className={`row justify-content-center ${cascading.swapcardrow}`}
          >
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5`}>
              <div className={` ${cascading.cardstyle}`}>
                <div className={`${cascading.toprow}`}>
                  <div className={`${cascading.backsec}`}>
                    <div className={`${cascading.backbtn}`}>
                      <Link to="/liquidity">
                        {" "}
                        <FaArrowLeft fill="#fff" fontSize={18} />
                      </Link>
                    </div>
                    <div>
                      <p className={`${cascading.headings}`}>
                        Remove {`${fromToken?.symbol}-${toToken?.symbol}`}{" "}
                        liquidity
                      </p>
                      <p className={`${cascading.desc}`}>
                        To receive{" "}
                        {`${fromToken?.symbol} and ${toToken?.symbol}`}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`${cascading.tabrow}`}>
                  <p className={`${cascading.labelname}`}>Amount</p>
                  <button
                    className={`btn ${cascading.tabbtn}`}
                    onClick={() => setRemovetab(!removetab)}
                  >
                    {removetab ? "Simple" : "Detailed"}
                  </button>
                </div>
                {removetab ? (
                  <>
                    <div className={`${cascading.inputsec} mb-4`}>
                      <div className={`${cascading.balancerow}`}>
                        <p className={`${cascading.inputlabel}`}>Input</p>
                        {/* {userdetail?.walletStatus == "connect" &&
                          !isEmpty(getWalletAddress()) && (
                            <p className={`${cascading.inputlabel}`}>
                              Balance :{token?.lpBalance}
                            </p>
                          )} */}

{userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && <p className={`${cascading.inputlabel}`}>Balance :{isEmpty(token?.lpBalance) ? 0 : roundToSignificant(token?.lpBalance,6)}</p>}
                      </div>

                      <div className={`${cascading.inputsecrow}`}>
                        <div>
                          <input
                            type="text"
                            className={`from-control`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="0.0"
                            name="lpValue"
                            value={lpValue}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        </div>

                        <div
                          className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                        >
                          {userdetail?.walletStatus == "connect" &&
                            !isEmpty(getWalletAddress()) && (
                              <button
                                className={`btn ${cascading.currencybtn}`}
                                onClick={() => {
                                  lpMax();
                                }}
                              >
                                {" "}
                                Max{" "}
                              </button>
                            )}
                          <button
                            className={`btn ${cascading.currencybtn}`}
                            onClick={() => {
                              setCommontoken(true);
                            }}
                          >
                            {!isEmpty(fromToken) &&
                            !isEmpty(fromToken) &&
                            pairInfo !== ZEROTH_ADDRESS ? (
                              <>
                                <img
                                  src={fileObjectUrl(fromToken?.image)}
                                  alt="bnb"
                                  className={`img-fluid ${cascading.coinimg}`}
                                />
                                <img
                                  src={fileObjectUrl(toToken?.image)}
                                  alt="bnb"
                                  className={`img-fluid ${cascading.coinimg}`}
                                />
                              </>
                            ) : (
                              ""
                            )}
                            {token?.lpbalance == 0 || pairInfo === ZEROTH_ADDRESS
                              ? "Select a currency"
                              : `${fromToken?.symbol}-${toToken?.symbol}`}{" "}
                            <FaAngleDown fill="#fff" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={`row ${cascading.arrowrow}`}>
                      <div className={`${cascading.swaparrow}`}>
                        <FaArrowDown />
                      </div>
                    </div>
                    <div className={`${cascading.inputsec} mb-4`}>
                      <div className={`${cascading.balancerow}`}>
                        <p className={`${cascading.inputlabel}`}>Output</p>
                        {/* {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : {fromToken?.balance}</p>} */}
                      </div>

                      <div className={`${cascading.inputsecrow}`}>
                        <div>
                          <input
                            type="email"
                            className={`from-control`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="0.0"
                            name="out1"
                            value={toFixedNumber(out1)}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        </div>

                        <div
                          className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                        >
                          {userdetail?.walletStatus == "connect" &&
                            !isEmpty(getWalletAddress()) && (
                              <button
                                className={`btn ${cascading.currencybtn}`}
                                onClick={() => {
                                  lpMax();
                                }}
                              >
                                {" "}
                                Max{" "}
                              </button>
                            )}
                          <button
                            className={`btn ${cascading.currencybtn}`}
                            onClick={() => {
                              setTokenshow(true);
                              setCommontoken(true);
                            }}
                          >
                            {!isEmpty(fromToken) ? (
                              <img
                                src={fileObjectUrl(fromToken?.image)}
                                alt="bnb"
                                className={`img-fluid ${cascading.coinimg}`}
                              />
                            ) : (
                              ""
                            )}
                            {isEmpty(fromToken)
                              ? "Select a currency"
                              : fromToken && fromToken?.symbol}{" "}
                            <FaAngleDown fill="#fff" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={`row ${cascading.arrowrow}`}>
                      <div className={`${cascading.swaparrow}`}>
                        <FaPlus />
                      </div>
                    </div>
                    <div className={`${cascading.inputsec} mb-4`}>
                      <div className={`${cascading.balancerow}`}>
                        <p className={`${cascading.inputlabel}`}>Output</p>
                        {/* {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : {toToken?.balance}</p>} */}
                      </div>

                      <div className={`${cascading.inputsecrow}`}>
                        <div>
                          <input
                            type="email"
                            className={`from-control`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="0.0"
                            name="out2"
                            value={toFixedNumber(out2)}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        </div>

                        <div
                          className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}
                        >
                          {userdetail?.walletStatus == "connect" &&
                            !isEmpty(getWalletAddress()) && (
                              <button
                                className={`btn ${cascading.currencybtn}`}
                                onClick={() => {
                                  lpMax();
                                }}
                              >
                                {" "}
                                Max{" "}
                              </button>
                            )}
                          <button
                            className={`btn ${cascading.currencybtn}`}
                            onClick={() => {
                              setTokenshow2(true);
                              setCommontoken(true);
                            }}
                          >
                            {!isEmpty(toToken) ? (
                              <img
                                src={fileObjectUrl(toToken?.image)}
                                alt="bnb"
                                className={`img-fluid ${cascading.coinimg}`}
                              />
                            ) : (
                              ""
                            )}
                            {isEmpty(toToken)
                              ? "Select a currency"
                              : toToken && toToken?.symbol}{" "}
                            <FaAngleDown fill="#fff" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {token?.lpBalance > 0 && pairInfo !== ZEROTH_ADDRESS ? (
                      <div className={`${cascading.pricesec}`}>
                        <p className="text-white">Prices</p>
                        <div className={`${cascading.pricecard}`}>
                          <ul>
                            <li>
                              <p>1 {fromToken?.symbol} =</p>
                              <p>
                                {toTokenPer} {toToken?.symbol}
                              </p>
                            </li>
                            <li>
                              <p>1 {toToken?.symbol} =</p>
                              <p>
                                {fromTokenPer} {fromToken?.symbol}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <div className={`${cascading.rangesec}`}>
                      {/* <p className={`${cascading.rangeshow}`}>{points} %</p> */}
                      <p className={`${cascading.rangeshow}`}>{ token?.lpBalance > 0 && pairInfo !== ZEROTH_ADDRESS ? parseFloat(points).toFixed(0) : 0} %</p>


                      <input
                        type="range"
                        className={`form-range ${cascading.rangeinput}`}
                        id="customRange"
                        name="points"
                        min="0"
                        max="100"
                        value={points}
                        onChange={(e) => {
                          rangeOnchange(e.target.value);
                        }}
                      />
                      <div className={`${cascading.btngrpsec}`}>
                        <button
                          className={`btn ${cascading.btnstyle}`}
                          onClick={() => {
                            rangeOnchange(25);
                          }}
                        >
                          25%
                        </button>
                        <button
                          className={`btn ${cascading.btnstyle}`}
                          onClick={() => {
                            rangeOnchange(50);
                          }}
                        >
                          50%
                        </button>
                        <button
                          className={`btn ${cascading.btnstyle}`}
                          onClick={() => {
                            rangeOnchange(75);
                          }}
                        >
                          75%
                        </button>
                        <button
                          className={`btn ${cascading.btnstyle}`}
                          onClick={() => {
                            rangeOnchange(100);
                          }}
                        >
                          Max
                        </button>
                      </div>
                    </div>
                    <div className={`row ${cascading.arrowrow}`}>
                      <div className={`${cascading.swaparrow}`}>
                        <FaArrowDown />
                      </div>
                    </div>

                    <div className={`${cascading.pricesec}`}>
                      <p className={`${cascading.pricename}`}>
                        You will Receive
                      </p>
                      <div className={`${cascading.pricecard}`}>
                        <ul>
                          <li>
                            <p>
                              <img
                                src={fileObjectUrl(fromToken?.image)}
                                className={`img-fluid ${cascading.coinimg}`}
                              />{" "}
                              {fromToken?.symbol}{" "}
                            </p>
                            <p>{isEmpty(out1) ? "-" : toFixedNumber(out1)}</p>
                          </li>
                          <li>
                            <p>
                              <img
                                src={fileObjectUrl(toToken?.image)}
                                className={`img-fluid ${cascading.coinimg}`}
                              />{" "}
                              {toToken?.symbol}
                            </p>
                            <p>{isEmpty(out2) ? "-" : toFixedNumber(out2)}</p>
                          </li>
                          <li>
                            <p></p>
                            {!isEmpty(changeBNB) ? (
                              <p
                                onClick={() => {
                                  changeToken();
                                }}
                              >
                                Receive {changeBNB}
                              </p>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>

                    {token?.lpBalance > 0 && pairInfo !== ZEROTH_ADDRESS ? (
                      <div className={`${cascading.pricesec}`}>
                        <p className={`text-white ${cascading.pricename}`}>
                          Prices
                        </p>
                        <div className={`${cascading.pricecard}`}>
                          <ul>
                            <li>
                              <p>1 {fromToken?.symbol} =</p>
                              <p>
                                {toTokenPer} {toToken?.symbol}
                              </p>
                            </li>
                            <li>
                              <p>1 {toToken?.symbol} =</p>
                              <p>
                                {fromTokenPer} {fromToken?.symbol}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <div className={`${cascading.btnsec}`}>
                  {/* <button
                    className={`btn ${cascading.connect_btn}`}
                    onClick={async () => {
                      let data = await ApproveToken(
                        token?.lpaddress,
                        getRouterAddress()
                      );
                      setLpApprove(data?.status);
                    }}
                    disabled={lpApprove}
                  >
                    Enable
                  </button> */}

<button className={`btn ${cascading.connect_btn}`} onClick={async () => { let data = await ApproveToken(token?.lpaddress, getRouterAddress()); setLpApprove(data?.status) }} disabled={lpApprove}>Enable</button>


                  {/* <button
                    className={`btn ${cascading.connect_btn}`}
                    onClick={() => {
                      setConfirmRemove(true);
                    }}
                    disabled={lpApprove == false || lpValue == 0}
                  >
                    Remove
                  </button> */}

<button className={`btn ${cascading.connect_btn}`} onClick={() => { setConfirmRemove(true) }} disabled={lpApprove == false || lpValue == 0 ||  token?.lpBalance == 0 || pairInfo == ZEROTH_ADDRESS } >Remove</button>
                </div>
              </div>
              {token?.lpBalance > 0 && pairInfo !== ZEROTH_ADDRESS ? (
                <div className={`${cascading.balancecardsec} mt-4`}>
                  <p className={`${cascading.head}`}>
                    LP tokens in your wallet
                  </p>
                  <ul>
                    <li>
                      <p>
                        <img
                          src={fileObjectUrl(fromToken?.image)}
                          className={`img-fluid ${cascading.coinimg}`}
                        />{" "}
                        <img
                          src={fileObjectUrl(toToken?.image)}
                          className={`img-fluid ${cascading.coinimg}`}
                        />{" "}
                        {`${fromToken?.symbol}-${toToken?.symbol}`} LP:
                      </p>
                      {/* <p>{token?.lpBalance}</p> */}
                      <p>{roundToSignificant(token?.lpBalance,6)}</p>
                    </li>
                    <li>
                      <p>Share of Pool:</p>
                      <p>
                        {!isEmpty(token?.poolShare) && !isNaN(token?.poolShare)
                          ? roundToSignificant(token?.poolShare)?.toFixed(2)
                          : "-"}
                        %
                      </p>
                    </li>
                    <li>
                      <p>Pooled {fromToken?.symbol}:</p>
                      <p>{roundToSignificant(token?.reserveA)}</p>
                    </li>
                    <li>
                      <p>Pooled {toToken?.symbol}:</p>
                      <p>{roundToSignificant(token?.reserveB)}</p>
                    </li>
                  </ul>
                </div>
              ) : pairInfo !== ZEROTH_ADDRESS ? (
                <div className={`${cascading.desccardsec} mt-4`}>
                  <p className={`${cascading.head}`}>
                    <img
                      src={iconimg1}
                      className={`img-fluid ${cascading.coinimg}`}
                    />{" "}
                    By adding liquidity you will earn 0.17% of all trades on
                    this pair propotional to your share of the pool.Fees are
                    added to the pool, accure in real time and can be claimed by
                    withdrawing your liquidity.
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {walletmodalshow && (
        <Walletmodal
          show={walletmodalshow}
          onHide={() => setWalletmodalshow(false)}
        />
      )}
      {recentshow && (
        <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />
      )}
      {/* {tokenshow && <Selecttoken show={tokenshow} commontoken={commontoken} onHide={() => { setTokenshow(false); setCommontoken(false) }} />} */}
      {settingshow && (
        <Settingsmodal
          show={settingshow}
          onHide={() => setSettingshow(false)}
        />
      )}
      {tokenshow && (
        <Selecttoken
          show={tokenshow}
          SetToken={(data) => {
            onFromTokenChange(data);
          }}
          commontoken={commontoken}
          onHide={() => {
            setTokenshow(false);
            setCommontoken(false);
          }}
        />
      )}
      {tokenshow2 && (
        <Selecttoken
          show={tokenshow2}
          SetToken={(data) => {
            console.log(data, "onToTokenChange");
            onToTokenChange(data);
          }}
          commontoken={commontoken}
          onHide={() => {
            setTokenshow2(false);
            setCommontoken(false);
          }}
        />
      )}
      {confirmRemove && (
        <ConfirmRemovemodal
          show={confirmRemove}
          supplyData={() => {
            supplyData();
          }}
          supplyObj={obj}
          pairInfo={pairInfo}
          onHide={() => setConfirmRemove(false)}
        />
      )}
      {succesSupply && (
        <CompletedRemovemodal
          show={succesSupply}
          onHide={() => setSuccesSupply(false)}
        />
      )}
      {waitingRemove && (
        <WaitingRemovemodal
          show={waitingRemove}
          supplyObj={obj}
          onHide={() => setWaitingRemove(false)}
        />
      )}
    </>
  );
}
