import { CURRENT_CHAIN_ID, useWeb3 } from "./useWeb3"
import PREDICTION_ABI from "../config/abi/Prediction.json"
import { PREDICTION } from "../config/prediction";
import ORACLE_ABI from '../config/abi/oracle.json'
import { ORACLE } from "../config/oracle";

import { getWalletAddress } from "../lib/localStorage";
import { toFixedNumber } from "./useStake";
import { toast } from "react-hot-toast";
import { getTokenPricesFromFarm } from "./useFarms";
import { getTokens } from "./useTokens";
import { NumberChange } from '../hooks/useCommon'

export const UseFetchPredictionRecords = async (currentEpoch = null) => {
    try {
        const web3 = await useWeb3();
        const predictionContract = new web3.eth.Contract(PREDICTION_ABI, PREDICTION[CURRENT_CHAIN_ID()].contract);
        currentEpoch = !currentEpoch ? await predictionContract.methods.currentEpoch().call() : currentEpoch;

        let records = [];

        // let start = parseInt(currentEpoch) - 3
        let start = parseInt(currentEpoch) - 3 > 1 ? parseInt(currentEpoch) - 3 : 1;
        let end = parseInt(currentEpoch) + 2
        const decimals = PREDICTION[CURRENT_CHAIN_ID()].decimals
        console.log("start : ", start, end)
        let intervalTime = await predictionContract.methods.intervalSeconds().call()
        let count = 1
        //closedtime
        let closedtime = 0
        let initialInterval = intervalTime
        for (var i = start; i <= end; i++) {
            const round = await predictionContract.methods.rounds(i).call();
            console.log(`Rount #${i} : ${JSON.stringify(round)}`)
            let STATUS = (i == currentEpoch - 1 ? "Live" : (round.oracleCalled ? "Expired" : "Later"));
            STATUS = i == currentEpoch ? "Next" : STATUS;
            let increaseValue = ((round.closePrice - round.lockPrice) / 10 ** decimals).toFixed(4)
            closedtime = STATUS == 'Next' ? round.startTimestamp : closedtime
            let latetCount = STATUS == 'Later' ? count * intervalTime : 0
            initialInterval = new Date(closedtime * 1000).getTime() + intervalTime * 1000
            let Interval = STATUS == 'Later' ? new Date(closedtime * 1000).getTime() + latetCount * 1000 : 0

            if (STATUS == 'Live') {
                var FetchLiveData = await UseFetchLive()
            }

            let UPPool = ((Number(round.totalAmount) / Number(round.bullAmount)))
            UPPool = isNaN(UPPool) ? 0 : UPPool
            console.log("UPPoolUPPoolUPPool", UPPool);

            let DownPool = ((Number(round.totalAmount) / Number(round.bearAmount)))
            DownPool = isNaN(DownPool) ? 0 : DownPool

            let pricePool = Number(round.totalAmount) / 10 ** 18
            pricePool = isNaN(pricePool) ? (0).toFixed(4) : parseFloat(pricePool).toFixed(4)

            records.push(
                {
                    id: i,
                    cardType: STATUS,
                    title: STATUS,
                    icon: "",
                    cardCount: `#${i}`,
                    payout: UPPool == 'Infinity' ? 0 : (UPPool).toFixed(4),
                    priceType: "Closed Price",
                    priceValue: (round.closePrice / 10 ** decimals).toFixed(4),
                    increaseValue: STATUS == 'Live' ? ((FetchLiveData?.answer - round.lockPrice) / 10 ** decimals).toFixed(4) : ((round.closePrice - round.lockPrice) / 10 ** decimals).toFixed(4),
                    type: increaseValue > 0 ? 'up' : 'down',
                    latetCount: STATUS == 'Later' ? Interval : 0,
                    livedata: STATUS == 'Live' ? (FetchLiveData?.answer / 10 ** decimals).toFixed(4) : 0,
                    lockedPrice: (round.lockPrice / 10 ** decimals).toFixed(4),
                    pricePool: pricePool,
                    downPayout: DownPool == 'Infinity' ? 0 : (DownPool).toFixed(4),
                }
            )
            count = STATUS == 'Later' ? count + 1 : count
        }
        let FetchliveBalance = records?.find((vals) => (vals?.cardType == 'Live'))
        console.log("fetch recored :", records)
        return ({ records, initialInterval, FetchliveBalance });
    } catch (err) {
        console.log("UseFetchPredictionRecords err", err);
    }
}

export const UseBetBull = async (id, amount) => {
    try {
        const web3 = await useWeb3();
        const predictionContract = new web3.eth.Contract(PREDICTION_ABI, PREDICTION[CURRENT_CHAIN_ID()].contract);
        const account = web3.utils.toChecksumAddress(getWalletAddress())
        const gasPrice = await web3.eth.getGasPrice();
        const gasLimit = await web3.eth.estimateGas({
            from: account,
            to: PREDICTION[CURRENT_CHAIN_ID()].contract,
            data: await predictionContract.methods.betBull(id).encodeABI(),
            value: toFixedNumber(parseInt(amount * 10 ** 18))
        })

        const bull = predictionContract.methods.betBull(id).send({ from: account, value: toFixedNumber(parseInt(amount * 10 ** 18)), gasPrice: gasPrice, gasLimit: gasLimit });
        await toast.promise(bull, {
            loading: 'Betting Up ...',
            success: 'Bet Successful',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
        console.log("bullbull", bull);
        return ({ status: true, message: 'successfully bit', success: bull })
    }
    catch (e) {
        console.log("UseBetBull", e);
        return ({ status: false, message: 'error occurred', error: e })

    }
}

export const UseBetBear = async (id, amount) => {

    try {
        const web3 = await useWeb3();
        const predictionContract = new web3.eth.Contract(PREDICTION_ABI, PREDICTION[CURRENT_CHAIN_ID()].contract);
        const account = web3.utils.toChecksumAddress(getWalletAddress())
        const gasPrice = await web3.eth.getGasPrice();
        const gasLimit = await web3.eth.estimateGas({
            from: account,
            to: PREDICTION[CURRENT_CHAIN_ID()].contract,
            data: await predictionContract.methods.betBear(id).encodeABI(),
            value: toFixedNumber(parseInt(amount * 10 ** 18))
        })
        const bull = predictionContract.methods.betBear(id).send({ from: account, value: toFixedNumber(parseInt(amount * 10 ** 18)), gasPrice: gasPrice, gasLimit: gasLimit });
        await toast.promise(bull, {
            loading: 'Betting Down  ...',
            success: 'Bet Successful',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
        console.log("bullbull", bull);
        return ({ status: true, message: 'successfully bit', success: bull })
    }
    catch (e) {
        console.log(e);
        return ({ status: false, message: 'error occurred', error: e })
    }
}

export const UseFetchUserRounds = async () => {
    try {
        let web3 = await useWeb3();
        let predictionContract = new web3.eth.Contract(PREDICTION_ABI, PREDICTION[CURRENT_CHAIN_ID()].contract);
        let address = getWalletAddress()
        let records = [];

        let RecordsLength = await predictionContract.methods.getUserRoundsLength(address).call()
        let AllHistoryRecords = await predictionContract.methods.getUserRounds(address, 0, RecordsLength).call()
        for (var i = 0; i < RecordsLength; i++) {
            let epoch = AllHistoryRecords[0][i]
            let data = AllHistoryRecords[1][i]
            let RoundData = await predictionContract.methods.rounds(epoch).call()
            let isClaimable = await predictionContract.methods.claimable(epoch, getWalletAddress()).call()
            let UPPool = ((Number(RoundData.totalAmount) / Number(RoundData.bullAmount)))
            UPPool = isNaN(UPPool) ? 0 : UPPool
            let obj = {
                ...data,
                'epoch': epoch,
                isClaimable: isClaimable,
                Uppayout: UPPool == 'Infinity' ? 0 : (UPPool).toFixed(4),

            }
            records.push(obj)
        }
        console.log("recordData", records);
        return records;

    } catch (err) {
        console.log("UseFetchUserRounds", err);
    }
}

export const UseFetchPnl = async () => {
    try {
        let web3 = await useWeb3();
        let predictionContract = new web3.eth.Contract(PREDICTION_ABI, PREDICTION[CURRENT_CHAIN_ID()].contract);
        let address = getWalletAddress()

        let records = [];

        let RecordsLength = await predictionContract.methods.getUserRoundsLength(address).call()
        let AllHistoryRecords = await predictionContract.methods.getUserRounds(address, 0, RecordsLength).call()

        let fetchAmount = AllHistoryRecords[1]?.reduce((total = 0, a) => {
            total = NumberChange(total) + NumberChange(a.amount)
            return total
        }, 0);

        var NetResult = (fetchAmount / 10 ** 18)

        for (var i = 0; i < AllHistoryRecords[0].length; i++) {
            let epoch = AllHistoryRecords[0][i]
            let val = AllHistoryRecords[1][i]
            let fetchBalance = getTokenPricesFromFarm(address)
            let WbnbAddress = getTokens()[1]?.address
            let BnbValue = fetchBalance[`${WbnbAddress.toLowerCase()}`]
            let RoundData = await predictionContract.methods.rounds(epoch).call()
            let isClaimable = await predictionContract.methods.claimable(epoch, getWalletAddress()).call()
            let Result = (RoundData.closePrice > RoundData.lockPrice && val.position == '0') || (RoundData.closePrice < RoundData.lockPrice && val.position == '1') ? 'won' : 'loss'

            let UPPool = ((Number(RoundData.totalAmount) / Number(RoundData.bullAmount)))
            UPPool = isNaN(UPPool) ? 0 : UPPool

            let obj = {
                ...val,
                BnbValues: parseFloat(val.amount) / 10 ** 18 * BnbValue,
                wonData: Result > 0 ? Result : 0,
                LossData: Result < 0 ? Result : 0,
                type: Result,
                epoch: epoch,
                isClaimable: isClaimable,
                Uppayout: UPPool == 'Infinity' ? 0 : (UPPool).toFixed(4),
            }

            records.push(obj)
            if (records.length - 1 == i) {
                var wonData = records.filter((val) => { return val.type == 'won' })
                var lossData = records.filter((val) => { return val.type == 'loss' })
            }
        }

        let fetchWonAmount = wonData?.reduce((total = 0, a) => {
            total = NumberChange(total) + NumberChange(a.amount)
            return total
        }, 0);
        let AverageAmount = NumberChange(fetchWonAmount / wonData?.length)
        var AvgValue = (AverageAmount / 10 ** 18)
        return { records, NetResult, AvgValue, wonData, lossData };
    } catch (err) {
        console.log("UseFetchPnl", err);
    }
}

export const UseFetchPredictionClaim = async (position) => {
    try {

        const web3 = await useWeb3();
        const predictionContract = new web3.eth.Contract(PREDICTION_ABI, PREDICTION[CURRENT_CHAIN_ID()].contract);
        const address = getWalletAddress()
        const ClaimRecords = predictionContract.methods.claim([position]).send({
            from: address
        });

        await toast.promise(ClaimRecords, {
            loading: 'Claiming ...',
            success: 'Claim Successful',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
        return ClaimRecords;

    } catch (err) {
        console.log("UseFetchPredictionClaim err", err);
    }
}

export const UseFetchLive = async () => {
    try {
        const web3 = await useWeb3();
        const oracleContract = new web3.eth.Contract(ORACLE_ABI, ORACLE[CURRENT_CHAIN_ID()].contract);
        let LiveRoundData = await oracleContract.methods.latestRoundData().call()
        return LiveRoundData
    } catch (err) {
        console.log("UseFetchLive", err);
    }
}

export const EstimateDefaultGasFees = async (balance) => {
    try {
        let web3 = await useWeb3();
        let GasPrice = await web3.eth.getGasPrice();
        let getGasPrice = web3.utils.toHex(parseFloat(GasPrice));
        let gaslimit = web3.utils.toHex(200000);
        let fee = gaslimit * getGasPrice;
        return (fee / 10 ** 18)

    } catch (err) {
        console.log("EstimateDefaultGasFees err", err);
    }
}

export const MinBitAmount = async () => {
    try {
        let web3 = await useWeb3();
        let predictionContract = new web3.eth.Contract(PREDICTION_ABI, PREDICTION[CURRENT_CHAIN_ID()].contract);
        let minBetamount = await predictionContract.methods.minBetAmount().call()
        const decimals = PREDICTION[CURRENT_CHAIN_ID()].decimals
        return (minBetamount / 10 ** decimals);
    } catch (err) {
        console.log("MinBitAmount err", err);
    }
}