import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdHistory } from "react-icons/md";
import cascading from "../../assests/css/liquidity.module.css";
import Footer from "../../common/footer";
import { FaArrowDown } from "react-icons/fa";
import { ImPlus } from "react-icons/im";

import Header from "../../common/header";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BigNumber from "bignumber.js";

import { FaAngleDown, FaPlus, FaArrowLeft } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import bnbimg from "../../assests/images/bnb.png";
import Walletmodal from "../../modals/walletmodal";
import { useSelector } from "react-redux";
import Recenthistory from "../../modals/recenthistory";
import Selecttoken from "../../modals/selecttoken";
import { Tooltip } from "react-bootstrap";
import Settingsmodal from "../../modals/settingsmodal";

//lib
import { isEmpty } from "../../lib/isEmpty";
import fileObjectUrl from "../../lib/img";
import { toFixedNumber } from "../../lib/FixedNumber";
import { getWalletAddress } from "../../lib/localStorage";

//abi
import pair_ABI from "../../config/abi/pairAbi.json";
import { ZEROTH_ADDRESS } from '../../config/env';
import {roundToSignificant} from '../../hooks/useCommon'

//hooks
import {
  getTokens,
  GetCurrencyBalance,
  multicall,
} from "../../hooks/useTokens";
import { ApproveToken, checkIsApproved, Balance } from "../../hooks/useErc20";
import { Addliquidity, AddLiquidityETH } from "../../hooks/useRouter";
import { GetPairInfo } from "../../hooks/useFactory";

export default function FindLiquidity() {
  const navigate = useNavigate();

  const { state } = useLocation();
  console.log("state", state.poolShare);

  const [swapsec, setSwapsec] = useState(true);
  const [walletmodalshow, setWalletmodalshow] = useState(false);
  const [recentshow, setRecentshow] = useState(false);
  const [tokenshow, setTokenshow] = useState(false);
  const [tokenshow2, setTokenshow2] = useState(false);
  const [settingshow, setSettingshow] = useState(false);
  const [commontoken, setCommontoken] = useState(false);
  const userdetail = useSelector((state) => state.admin);
  const [fromToken, setFromToken] = useState("");
  const [toToken, setToToken] = useState("");
  const [lpBalance, setlpBalance] = useState(0);
  const [poolA, setPoolA] = useState(0);
  const [poolB, setPoolB] = useState(0);
  const [pairInfo, setPairInfo] = useState("");
  const [poolShare, setPoolShare] = useState(0);

  const onFromTokenChange = async (value) => {
    if (value.address == toToken?.address) {
      setToToken(fromToken);
    }
    setFromToken(value);
  };

  const onToTokenChange = async (value) => {
    console.log(value, "value");
    if (value.address == fromToken?.address) {
      setFromToken(toToken);
    }
    setToToken(value);
  };
  console.log(toToken, "toToken");

  useEffect(() => {
    getpair();
  }, [fromToken, toToken]);

  const getpair = async () => {
    console.log("fromToken", fromToken?.address, toToken?.address);
    var get = await GetPairInfo(fromToken?.address, toToken?.address);
    console.log("getPairInfo_getpair", get);
    setPairInfo(get);
    var bal = await Balance(get);
    console.log("bal", bal);
    setlpBalance(bal);
    let calls = [
      {
        address: get,
        name: "getReserves",
      },
      {
        address: get,
        name: "token0",
      },
      {
        address: get,
        name: "token1",
      },
      {
        address: get,
        name: "totalSupply",
      },
    ];
    if (!isEmpty(getWalletAddress())) {
      calls.push({
        address: get,
        name: "balanceOf",
        params: [getWalletAddress()],
      });
    }
    var pooldata = await multicall(pair_ABI, calls);
    if (pooldata) {
      setPoolA(new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18);
      setPoolB(new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18);
      let token1 = getTokens().find(
        (val) => val?.address?.toLowerCase() == pooldata[1][0]?.toLowerCase()
      );
      console.log("token1", token1);
      let token2 = getTokens().find(
        (val) => val?.address?.toLowerCase() == pooldata[2][0]?.toLowerCase()
      );
      console.log("token2", token2);
      let totalSupply =
        new BigNumber(pooldata[3][0]._hex).toNumber() / 10 ** 18;

      let lpBalance = new BigNumber(pooldata[4][0]._hex).toNumber() / 10 ** 18;
      let poolShare = ((lpBalance * 100) / totalSupply).toFixed(2);
      setPoolShare(poolShare);
      console.log(
        "multicall_pooldata",
        pooldata,
        poolShare,
        new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18,
        new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18
      );
    }
  };

  return (
    <>
      <Header />
      <div
        className={`${cascading.innerpages} ${cascading.liquiditypage} ${cascading.findpage}`}
      >
        <div className={`container`}>
          <div
            className={`row justify-content-center ${cascading.swapcardrow}`}
          >
            <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 `}>
              <div className={` ${cascading.cardstyle}`}>
                <div className={`${cascading.toprow}`}>
                  <div className={`${cascading.backsec}`}>
                    <div className={`${cascading.backbtn}`}>
                      <Link to="/liquidity">
                        {" "}
                        <FaArrowLeft fill="#7645d9" fontSize={18} />
                      </Link>
                    </div>
                    <div>
                      <p className={`${cascading.headings}`}>Import Pool</p>
                      <p className={`${cascading.desc}`}>
                        Import an existing pool
                      </p>
                    </div>
                  </div>
                  <div className={`${cascading.iconsec}`}>
                    <IoMdSettings onClick={() => setSettingshow(true)} />
                    <MdHistory onClick={() => setRecentshow(true)} />
                  </div>
                </div>

                <div className={`${cascading.inputsec} mb-4 py-4 `}>
                  <button
                    className={`btn ${cascading.currencybtn} ${cascading.currencybtnnew}`}
                    onClick={() => {
                      setTokenshow(true);
                      setCommontoken(true);
                    }}
                  >
                    <div className={`${cascading.imgbtn}`}>
                      {/* <div> */}{" "}
                      <div>
                        {!isEmpty(fromToken) ? (
                          <img
                            src={fileObjectUrl(fromToken?.image)}
                            alt="bnb"
                            className={`img-fluid ${cascading.coinimg} me-2`}
                          />
                        ) : (
                          ""
                        )}
                        {isEmpty(fromToken)
                          ? "Select a currency"
                          : fromToken && fromToken?.symbol}{" "}
                      </div>
                      <FaAngleDown fill="#000" fontSize={16} />
                    </div>
                    {/* <img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB </div><FaAngleDown fill='#fff' /> */}
                  </button>
                </div>
                {/* <div className={`row ${cascading.arrowrow}`}>
                  <div className={`${cascading.swaparrow}`}>
                    <FaPlus />
                  </div>
                </div> */}
                <div
                  className={`${cascading.inputsec} ${cascading.inputsec2} mb-4 py-4`}
                >
                  <div className={`${cascading.swaparrow}`}>
                    <ImPlus />
                  </div>
                  <button
                    className={`btn ${cascading.currencybtn} ${cascading.currencybtnnew}`}
                    onClick={() => {
                      setTokenshow2(true);
                      setCommontoken(true);
                    }}
                  >
                    <div className={`${cascading.imgbtn}`}>
                      <div>
                        {!isEmpty(toToken) ? (
                          <img
                            src={fileObjectUrl(toToken?.image)}
                            alt="bnb"
                            className={`img-fluid ${cascading.coinimg} me-2`}
                          />
                        ) : (
                          ""
                        )}
                        {isEmpty(toToken)
                          ? "Select a currency"
                          : toToken && toToken?.symbol}{" "}
                      </div>

                      <FaAngleDown ill="#000" fontSize={16} />
                    </div>
                    {/* <img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB </div><FaAngleDown fill='#fff' /> */}
                  </button>
                </div>

                
                {isEmpty(fromToken) || isEmpty(toToken) ? (
                  <div className={`${cascading.findlabelsec}`}>
                    <p className={`${cascading.findlabelcontent} mb-0`}>
                      Select a token to find your liquidity.
                    </p>
                  </div>
                  
                ) : pairInfo !==
                  "0x0000000000000000000000000000000000000000" ? (
                    <>
                  <div className={`${cascading.findlabelsec}`}>
                    <p
                      className={`${cascading.findlabelcontent} text-center mb-0`}
                    >
                      Pool Found! 
                    
                    </p>
                  </div>
                  <div className="text-center mt-3">
                    <Link
                    to="/liquidity"
                    className={`${cascading.linktheme}`}
                  >
                    {" "}
                    Manage this pool
                  </Link>
                  </div>
                  </>
                ) : pairInfo ===
                  "0x0000000000000000000000000000000000000000" ? (
                  <div className={`${cascading.findlabelsec}`}>
                    <p className={`${cascading.findlabelcontent} mb-0`}>
                      Invalid pair.
                    </p>
                  </div>
                ) : (
                  ""
                )}

                                  {/* {isEmpty(fromToken) || isEmpty(toToken) ? <div className={`${cascading.findlabelsec}`}>
                                    <p className={`${cascading.findlabelcontent}`}>Select a token to find your liquidity.</p>
                                </div> : pairInfo === ZEROTH_ADDRESS ? <div className={`${cascading.findlabelsec}`}>
                                    <p className={`${cascading.findlabelcontent}`}>Invalid pair.</p>
                                </div> :
                                    pairInfo !== ZEROTH_ADDRESS && lpBalance > 0 ? <div className={`${cascading.findlabelsec}`}>
                                        <p className={`${cascading.findlabelcontent} text-center`}>Pool Found! <br></br>
                                            <Link to="/liquidity" className={`${cascading.linktheme}`}> Manage this pool</Link>
                                        </p>
                                    </div> :
                                        <div className={`${cascading.findlabelsec} text-center`}>
                                            <p className={`${cascading.findlabelcontent}`}>You don’t have liquidity in this pool yet.<br></br>
                                                <button className={`btn ${cascading.transbtn}`} onClick={() => { navigate("/add", { state: { key : 'find', fromToken: fromToken , toToken: toToken , poolShare : poolShare, reserveA : poolA, reserveB : poolB } }) }}>Add Liquidity
                                                </button>
                                            </p>
                                        </div>} */}

                {pairInfo &&
                pairInfo !== ZEROTH_ADDRESS && lpBalance > 0 ? (
                  <div className={`${cascading.balancecardsec} mt-4 `}>
                    <p className={`${cascading.head}`}>
                      LP tokens in your wallet
                    </p>
                    <ul>
                      <li>
                        <p>
                          <img
                            src={fileObjectUrl(fromToken?.image)}
                            className={`img-fluid ${cascading.coinimg}`}
                          />
                          <img
                            src={fileObjectUrl(toToken?.image)}
                            className={`img-fluid ${cascading.coinimg}`}
                          />
                          {/* <OverlayTrigger
                                            className={`${cascading.tooltip_style}`}
                                            key="right"
                                            placement="right"
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.
                                                </Tooltip>
                                            }
                                        >
                                            <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                        </OverlayTrigger> */}
                          {`${fromToken?.symbol}-${toToken?.symbol}`} LP
                        </p>
                        {/* <p>{lpBalance}</p> */}
                        <p>{toFixedNumber(lpBalance)}</p>
                      </li>
                      <li>
                        <p>Share of Pool:</p>
                        {/* <p>{poolShare}%</p> */}
                        <p>{roundToSignificant(poolShare,6)}%</p>
                      </li>
                      <li>
                        <p>Pooled {fromToken?.symbol}:</p>
                        {/* <p>{toFixedNumber(poolA)}</p> */}
                        <p>{roundToSignificant(poolA,6)}</p>

                      </li>
                      <li>
                        <p>Pooled {toToken?.symbol}:</p>
                        {/* <p>{toFixedNumber(poolB)}</p> */}
                        <p>{roundToSignificant(poolB,6)}</p>

                      </li>
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {walletmodalshow && (
        <Walletmodal
          show={walletmodalshow}
          onHide={() => setWalletmodalshow(false)}
        />
      )}
      {recentshow && (
        <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />
      )}
      {tokenshow && (
        <Selecttoken
          show={tokenshow}
          SetToken={(data) => {
            onFromTokenChange(data);
          }}
          commontoken={commontoken}
          onHide={() => {
            setTokenshow(false);
            setCommontoken(false);
          }}
        />
      )}
      {tokenshow2 && (
        <Selecttoken
          show={tokenshow2}
          SetToken={(data) => {
            console.log(data, "onToTokenChange");
            onToTokenChange(data);
          }}
          commontoken={commontoken}
          onHide={() => {
            setTokenshow2(false);
            setCommontoken(false);
          }}
        />
      )}
      {settingshow && (
        <Settingsmodal
          show={settingshow}
          onHide={() => setSettingshow(false)}
        />
      )}
    </>
  );
}
