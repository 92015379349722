import React from "react";
import cascading from "../assests/css/footerstyle.module.css";
import { FaTelegramPlane } from "react-icons/fa";
// import logo from "../assests/images/techlogo.png";
import logo from "../assests/images/usdtlogo.png";

import { Link } from "react-router-dom";
import twittericon from "../assests/images/footer/twitter.png";
import telegramicon from "../assests/images/footer/telegram.png";
import fileicon from "../assests/images/footer/file.png";
import mailicon from "../assests/images/footer/mail.png";
import twitterhover from "../assests/images/footer/twitterhover.png";
import telegramhover from "../assests/images/footer/telehover.png";
import filehover from "../assests/images/footer/filehover.png";
import mailhover from "../assests/images/footer/mailhover.png";
import { IoMdMail } from "react-icons/io";
import { FaFile } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";
export default function Footer() {
  return (
    <>
      <div className={`${cascading.footersec}`}>
        <section>
          <div className={`container`}>
            <div className={`${cascading.footerrow}`}>
              <img
                src={logo}
                alt="logo"
                className={`img-fluid ${cascading.footerlogo}`}
              />
              <div className={`${cascading.rightsidesec}`}>
                <div>
                  <ul className={`${cascading.sociallist}`}>
                    <li>
                      <Link to="">
                        <img
                          src={twittericon}
                          className={`img-fluid ${cascading.normal}`}
                          alt="twitter"
                        />
                        <img
                          src={twitterhover}
                          className={`img-fluid ${cascading.hovericon}`}
                          alt="twitter"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img
                          src={telegramicon}
                          className={`img-fluid ${cascading.normal}`}
                          alt="telegramicon"
                        />
                        <img
                          src={telegramhover}
                          className={`img-fluid ${cascading.hovericon}`}
                          alt="telegramicon"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img
                          src={fileicon}
                          className={`img-fluid ${cascading.normal}`}
                          alt="fileicon"
                        />
                        <img
                          src={filehover}
                          className={`img-fluid ${cascading.hovericon}`}
                          alt="fileicon"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img
                          src={mailicon}
                          className={`img-fluid ${cascading.normal}`}
                          alt="mailicon"
                        />
                        <img
                          src={mailhover}
                          className={`img-fluid ${cascading.hovericon}`}
                          alt="mailicon"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={`${cascading.centerline}`}></div>
                <div>
                  <ul className={`${cascading.routelist}`}>
                    <li>
                      <Link to="/swap">Trade</Link>
                    </li>
                    <li>
                      <Link to="/farms">Earn</Link>
                    </li>
                    <li>
                      <Link to="/prediction">Game</Link>
                    </li>
                    {/* <li>
                      <Link to="">Tokenomics</Link>
                    </li>
                    <li>
                      <Link to="">Whitepaper</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr className={`${cascading.footerline}`} />
        <div className={`${cascading.copysec}`}>
          <p>
            Copyright © 2024 <span>USDTSWAP</span> - All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
}
